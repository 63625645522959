import React from 'react';

import Page from '../../page';

export default class Apply extends React.Component {

  componentDidMount() {
    $('#application-form').submit((event) => {
      event.preventDefault();

      const form = $('#application-form');

      form.addClass('was-validated');

      if (!(form.get(0) as HTMLFormElement).checkValidity()) {
        event.stopPropagation();
        return;
      }

      const data = {
        name: $('#name').val(),
        email: $('#email').val(),
        dateOfBirth: $('#date-of-birth').val(),
        gender: $('input[name="gender"]:checked').val(),
        university: $('#university').val(),
        year: $('#year').val(),
        why: $('#why').val(),
        exposure: $('#exposure').val(),
        activities: $('input[name="activities"]:checked').map(function() { return $(this).val(); }).get().join(', '),
      };

      $.ajax({
        type: 'POST',
        url: '/b/apply',
        data: data,
        success: (data) => {
          $('#message-submission-error').hide();
          $('#message-submission-success').show();

          form.find('button[type=submit]').prop('disabled', true);

          form.trigger('reset');
          form.hide();
        },
        error: () => {
          $('#message-submission-error').show();
          window.location.hash = "#message-submission-error";
        }
      });
    });
  }

  render() {
    return (
      <Page>
        <h1>UniSalad Ambassador Application Form</h1>

        <p>
          UniSalad is looking for conﬁdent, outgoing ambassadors to help promote our student app throughout university.
        </p>

        <p>
          If you want to get involved in promoting the product, simply fill in the form below and the UniSalad team will respond to you in 7 working days!
        </p>

        <div id="message-submission-success" className="alert alert-success" role="alert" style={{ display: 'none' }}>
          <p>
            &#x1F601; Thanks for your application!
          </p>

          <p className="mb-0">
            You should receive an email from us shortly confirming we received your application.
          </p>
        </div>

        <div id="message-submission-error" className="alert alert-danger" role="alert" style={{ display: 'none' }}>
          <p>
            &#x1F61e; Sorry, an error occurred trying to submit your application.
          </p>

          <p className="mb-0">
            Please give us a minute and try again.
          </p>
        </div>

        <form id="application-form" className="needs-validation mt-4" noValidate>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input id="name" type="text" className="form-control" placeholder="Preferred name(s)" autoComplete="name" required />
            <div className="invalid-feedback">
              This field is required.
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <input id="email" type="email" className="form-control" placeholder="user@mail.com" autoComplete="email" aria-describedby="emailHelp" required />
            <div className="invalid-feedback">
              This field is required.
            </div>
            <small id="emailHelp" className="form-text text-muted">
              (Only used to contact you in regards to this ambassador position)
            </small>
          </div>
          <div className="form-group">
            <label htmlFor="date-of-birth">Date of Birth</label>
            <input id="date-of-birth" type="text" className="form-control" placeholder="01/01/2000" required />
            <div className="invalid-feedback">
              This field is required.
            </div>
          </div>
          <div className="form-group">
            <label>Gender</label>
            <div className="form-check">
              <input id="gender-male" type="radio" name="gender" className="form-check-input" value="Male" checked />
              <label className="form-check-label" htmlFor="gender-male">Male</label>
            </div>
            <div className="form-check">
              <input id="gender-female" type="radio" name="gender" className="form-check-input" value="Female" />
              <label className="form-check-label" htmlFor="gender-female">Female</label>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="university">University</label>
            <input id="university" type="text" className="form-control" placeholder="University of UniSalad" required />
            <div className="invalid-feedback">
              This field is required.
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="year">Current Year of Study</label>
            <input id="year" type="text" className="form-control" placeholder="2024" required />
            <div className="invalid-feedback">
              This field is required.
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="why">Tell us why you would like to be an ambassador for UniSalad</label>
            <textarea id="why" className="form-control" rows={6} placeholder="I think I would be a good choice because..." required></textarea>
            <div className="invalid-feedback">
              This field is required.
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="exposure">How would you generate exposure of the brand?</label>
            <textarea id="exposure" className="form-control" rows={6} placeholder="I would..." required></textarea>
            <div className="invalid-feedback">
              This field is required.
            </div>
          </div>
          <div className="form-group">
            <label>Which kind of ambassador activities would you be happy to participate in?</label>
            <div className="form-check">
              <input id="activities-one" type="checkbox" name="activities" className="form-check-input" value="On & around campus speaking to students about the brand" />
              <label className="form-check-label" htmlFor="activities-one">On & around campus speaking to students about the brand</label>
            </div>
            <div className="form-check">
              <input id="activities-two" type="checkbox" name="activities" className="form-check-input" value="Freshers stand promotion" />
              <label className="form-check-label" htmlFor="activities-two">Freshers stand promotion</label>
            </div>
            <div className="form-check">
              <input id="activities-three" type="checkbox" name="activities" className="form-check-input" value="Promoting in bars/nightclubs" />
              <label className="form-check-label" htmlFor="activities-three">Promoting in bars/nightclubs</label>
            </div>
            <div className="form-check">
              <input id="activities-four" type="checkbox" name="activities" className="form-check-input" value="Promote via your social media channels (instagram, twitter, snapchat, facebook. If applicable)" />
              <label className="form-check-label" htmlFor="activities-four">Promote via your social media channels (instagram, twitter, snapchat, facebook. If applicable)</label>
            </div>
            <div className="form-check">
              <input id="activities-five" type="checkbox" name="activities" className="form-check-input" value="Writing blogs / vlogging" />
              <label className="form-check-label" htmlFor="activities-five">Writing blogs / vlogging</label>
            </div>
            <div className="form-check">
              <input id="activities-six" type="checkbox" name="activities" className="form-check-input" value="Managing the UniSalad social media channels (instagram, twitter, facebook)" />
              <label className="form-check-label" htmlFor="activities-six">Managing the UniSalad social media channels (instagram, twitter, facebook)</label>
            </div>
            <div className="form-check">
              <input id="activities-seven" type="checkbox" name="activities" className="form-check-input" value="Taking photos of activities / the launch / the brand" />
              <label className="form-check-label" htmlFor="activities-seven">Taking photos of activities / the launch / the brand</label>
            </div>
          </div>
          <div className="d-flex">
            <button type="submit" className="btn btn-primary ml-auto px-4" style={{ backgroundColor: '#05c788', borderColor: '#05c788' }}>Send My Application</button>
          </div>
        </form>
      </Page>
    );
  }
}
