import React from 'react';
import { Link } from 'react-router-dom';

import Page from '../../page';

export default class Acknowledgements extends React.Component {

  render() {
    return (
      <Page>
        <h1>Acknowledgements</h1>

        <h2>unisalad.com</h2>

        <p>
          The <Link to="/">unisalad.com</Link> website (this website) uses the following licensable software or assets with permission.
          Each piece of third-party content is listed with the applicable license agreements.
        </p>

        <ol>
          <li>
            <strong>
              <a href="https://unsplash.com/photos/hzgs56Ze49s?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" target="_blank" rel="noopener noreferrer">Photograph</a>
            </strong> by Anthony Delanoix

            <p>
              Licensed under the <a href="/licenses/unsplash/unsplash-license.txt" target="_blank" rel="noopener noreferrer">Unsplash License</a>
            </p>
          </li>
        </ol>
      </Page>
    );
  }
}
