import React from 'react';

import Page from '../../page';

export default class Contact extends React.Component {

  componentDidMount() {
    $('#contact-form').submit((event) => {
      event.preventDefault();

      const form = $('#contact-form');

      form.addClass('was-validated');

      if (!(form.get(0) as HTMLFormElement).checkValidity()) {
        event.stopPropagation();
        return;
      }

      const data = {
        name: $('#name').val(),
        email: $('#email').val(),
        message: $('#message').val(),
      };

      $.ajax({
        type: 'POST',
        url: '/b/contact',
        data: data,
        success: (data) => {
          $('#message-submission-error').hide();
          $('#message-submission-success').show();

          form.find('button[type=submit]').prop('disabled', true);

          form.trigger('reset');
          form.hide();
        },
        error: () => {
          $('#message-submission-error').show();
          window.location.hash = "#message-submission-error";
        }
      });
    });
  }

  render() {
    return (
      <Page>
        <h1>Contact Us</h1>

        <p>
          Feel free to contact us with any requests, problems or feedback you may have.
          We will do our best to get back to you as quickly as possible.
        </p>

        <div id="message-submission-success" className="alert alert-success" role="alert" style={{ display: 'none' }}>
          <p>
            &#x1F601; Thanks for your message!
          </p>

          <p className="mb-0">
            You should receive an email from us shortly confirming we received your message.
          </p>
        </div>

        <div id="message-submission-error" className="alert alert-danger" role="alert" style={{ display: 'none' }}>
          <p>
            &#x1F61e; Sorry, an error occurred trying to submit your message.
          </p>

          <p className="mb-0">
            Please give us a minute and try again.
          </p>
        </div>

        <form id="contact-form" className="needs-validation mt-4" noValidate>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input id="name" type="text" className="form-control" placeholder="Preferred name(s)" autoComplete="name" required />
            <div className="invalid-feedback">
              This field is required.
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <input id="email" type="email" className="form-control" placeholder="user@mail.com" autoComplete="email" required />
            <div className="invalid-feedback">
              This field is required.
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea id="message" className="form-control" rows={6} placeholder="Hey, I wanted to know ..." required></textarea>
            <div className="invalid-feedback">
              This field is required.
            </div>
          </div>
          <div className="d-flex">
            <button type="submit" className="btn btn-primary ml-auto px-4" style={{ backgroundColor: '#05c788', borderColor: '#05c788' }}>Send My Message</button>
          </div>
        </form>
      </Page>
    );
  }
}
