import React from 'react';

import BlogPost from './blog-post';

export default class Blog5 extends React.Component {

  render() {
    return (
      <BlogPost>
        <h1 style={{ marginBottom: '16px' }}>Interview with your current SU officers</h1>

        <h3 style={{ marginBottom: '24px' }}>
          We spoke to three of your current Officers: on their experiences so far, dealing with sudden university
          closure, tips to stay positive during the rest of lockdown and advice to next year's elected Officers.
        </h3>

        <h5 className="mb-4">1) What is your SU Officer position?</h5>

        <p>
          <strong>A:</strong> Activities Officer (answers as ‘a' below)
        </p>

        <p>
          <strong>B: </strong> Postgraduate Officer (answering as ‘b' below)
        </p>

        <p>
          <strong>C:</strong> Sports Officer (answers as ‘c' below)
        </p>

        <h5 className="mb-4">
          2) Can you briefly explain what your main roles are?
        </h5>

        <p>
          <strong>A:</strong> Supporting, representing and empowering ~400 student groups (societies,
          Student-Run Services, Student-Led Projects) as well as supporting the organisation of SU events and activity.
        </p>

        <p>
          <strong>B:</strong> My main role comprises of representing all our Postgraduate students as well as supporting
          them. We have just under 10,000 Postgraduate students which makes almost a third of our university population.
          I work with the University and the Graduate school to make sure the voices of Postgraduate Students are heard.
          This goes in all forms from supporting induction, running events, academic representation through the
          education network and more.
        </p>

        <p>
          <strong>C:</strong> My main roles this year have been working on my manifesto and looking to improve the
          student experience in sport and physical activity. This includes looking at how we can deliver the best
          programmes for anyone from complete beginners, students playing for BUCS teams, students on satellite campuses
          and general gym users.
        </p>

        <p>
          I also helped in the planning of the Varsity 2020 Series, where we collaborate with Nottingham Trent each year
          to showcase sport across the city.
        </p>

        <h5 className="mb-4">
          3) How have you found your Officer experience so far, was it what you expected when you were elected?
        </h5>

        <p>
          <strong>A:</strong> It's been a rollercoaster of a year but definitely an exciting one! I was involved in 11
          student groups and a member of 4 committees as a student so lots of my job involves engaging with students in
          the ways I loved but on a full-time basis (and I get paid to do it!). It's not always an easy job and there
          are lots of unexpected challenges you have to face that students will never know about but being an SU Officer
          is a once-in-a-lifetime experience!
        </p>

        <p>
          <strong>B:</strong> I think it's safe to say that at the beginning of this year, neither officers, students
          nor staff had any idea some of the challenges this year would bring to us. From the current Covid-19 situation
          for example, it is something that nobody in any role has encountered and therefore it has been a challenge to
          adjust like many other people whilst continuing to support students who need our support now more than ever
          before due to the situation at hand.
        </p>

        <p>
          Overall however, I have loved my experience so far as it is always my passion to help people and make a
          difference. I have enjoyed representing so many students from such rich and diverse backgrounds not only in
          culture but educational history, life experience and from all walks of life.
        </p>

        <p>
          <strong>C:</strong> I have absolutely loved my year, it definitely wasn't what I expected but it's been a
          fantastic experience. You get the opportunity to work with some really great people across different
          departments in the Union and University, so it's a really diverse job and you get to learn a lot.
        </p>

        <p>
          We've had a pretty challenging year but I've had really great support from staff which has made it more
          manageable. I didn't expect to work with so many great staff members and students, I love knowing that
          something I've done at work has created a better experience for even just one student.
        </p>

        <h5 className="mb-4">4) What are your plans after your journey as an Officer finishes?</h5>

        <p>
          <strong>A:</strong> I currently don't have any specific plans as my initial intentions to travel and work
          abroad have been halted as a result of Covid-19. However, I'm passionate about marketing and events management
          so I'm looking for opportunities to improve the knowledge and experience I already have 🙂
        </p>

        <p>
          <strong>B:</strong> I would still love to be in a role where I can help and support people as that is what I
          feel I enjoy doing the most. The roles that comes to mind based off my interests is a technology consultant
          helping business with their technological needs.
        </p>

        <p>
          <strong>C:</strong> I want to move across and work in Higher Education Sport but obviously that's pretty
          difficult at the moment. Also, anyone who knows me knows I'm genuinely obsessed with the Green and Gold, so I
          don't know if I'd find it really difficult working at a different university.
        </p>

        <h5 className="mb-4">
          5) University life under Covid-19 has certainly been a very unique experience. How did you feel initially when
          the Uni decided to shut down? How did management look like in the early days?
        </h5>

        <p>
          <strong>A:</strong> Living with the knock-on effects of a pandemic has certainly been a surreal experience. I
          was actually off work ill with a cold when work-from-home measures were implemented so I wasn't able to
          collect everything I'd wanted to. It's certainly been a learning curve that no one could have seen coming and
          has caused us to have to think outside the box, for example with awards and recognition events. Fortunately,
          though, I'm very lucky: I'm healthy, I have food and I have a roof over my head. I'm very aware that other
          people aren't so fortunate.
        </p>

        <p>
          <strong>B:</strong> I think like many students I was hit with a large degree of uncertainty over what was
          going to happen and realised very quickly this was the right thing to do. Personally, I didn't really have
          time to prepare for what's to come because nobody really knew exactly what was coming. I knew that for many of
          my friends who are international students, even before the university moved to online teaching, they had to
          suddenly go home due to their respective governments announcing their border closures and lockdowns.
        </p>

        <p>
          I think the issue here is that whilst many plans and protocols were in place before the shut-down, nothing is
          as real as the reality of dealing with plans in real time and therefore this adjustment and also the situation
          has had so many effects on all aspects of society weather you're a student, staff and more. It all felt very
          unsure and uncertain in the first few days but in the coming weeks after there was a sense of re-assurance
          that there was better control over the scenario, in regard to the services and how well they are being managed
          and utilised.
        </p>

        <p>
          <strong>C:</strong> I was genuinely gutted. I realised pretty quickly that I wouldn't be back to work at the
          SU and get to see events like Sports Ball happen. We had spent months planning Varsity, and the night before
          the first event was when everything really got serious and we had to cancel. I just felt gutted for the staff
          who had organised the events and students who were excited to participate and watch but looking back it was
          obviously the right thing to do.
        </p>

        <p>
          I don't think anyone had anytime, to prepare really, I was still working at the SU up until we were told we
          couldn't, it was just really strange seeing how empty campus became in a matter of days.
        </p>

        <p>
          It just all felt really surreal. We keep hearing the word ‘unprecedented', and it really did feel like that.
          Every time it felt like you had a grip of the situation, new information would be released, and it was like
          back to square one. It was really worrying to begin with, but as everyone got their heads around it and
          working from home became the ‘new norm' I think we have all just settled into this new way of doing things.
        </p>

        <h5 className="mb-4">
          6) Hindsight is a wonderful thing as we know, but how do you feel the University and SU handled everything?
        </h5>

        <p>
          <strong>A:</strong> Considering no one could have seen this coming, I think that both the University and the
          SU have approached the situation really well. There's been a constant focus on the needs and priorities of
          students and how we can best support them and alleviate concerns. From my specific perspective, it's also been
          brilliant to still celebrate students' work prior to and in spite of the adversity caused by Covid-19.
        </p>

        <p>
          <strong>B:</strong> I think what's important to highlight here is that firstly we are still very much in the
          crisis so it's difficult to look back in hindsight when there are still many challenges left in front of us. I
          know that for many students and staff alike, communication was the largest area of concern, but we must all
          remember that we are all human beings and just like the rest of society and students, staff members have also
          had huge challenges and adjusting like everybody else.
        </p>

        <p>
          There are of course places where we can identify we didn't quite get it right but I don't believe now is the
          time to highlight such things but focus on the positives on how in the space of a week an entire university
          shifted to online teaching and many staff members who are also adjusting to new environments like students and
          the rest of society are doing their best to still deliver their teaching, marking and more.
        </p>

        <p>
          The many support services working hard to ensure students are getting the right amount of support they need
          and more, The Union creating a Facebook virtual community group (UoN Neighbours) to bring together our
          students from all over the world to still connect digitally and more. In times like this we need to do our
          best to remain positive and focus on those things as that is what will inspire us to get through this
          together.
        </p>

        <p>
          <strong>C:</strong> Considering the enormity of the situation, I think both the SU and University have dealt
          with it really well. You honestly wouldn't believe the number of meetings that occur daily with all
          departments planning for different scenarios as we move forward.
        </p>

        <p>
          I think the initial stages of lockdown were really difficult because it was just so unlike any other situation
          anyone had been in; it definitely took some time to figure out how to deal with the changes and still support
          students from afar. Moving forward though, I think there have been some really good outcomes for students,
          with the no detriment policy, UoN Sports online hub and just overall better communication to students. It's
          been really good to see how students have overcome the challenge as well by staying in touch online and
          running challenges.
        </p>

        <h5 className="mb-4">
          7) The end of lockdown is in sight; do you have any advice to students for the rest of the isolation period?
        </h5>

        <p>
          <strong>A:</strong> I'd say: “keep your eyes on the horizon”. Though we're living in worrying and uncertain
          times, there is a light at the end of the tunnel. We might not know when we'll reach that light, but we can
          draw on other countries to know that it will come in the not-so-distant future. I've seen people use this time
          to reflect and write down ideas of things to do or goals to achieve once lockdown is over so that they have a
          clear sense of drive and motivation once we find our new normal. It's ok to feel overwhelmed and a bit lost at
          the moment but it's also ok to plan for our future, whenever that may be.
        </p>

        <p>
          <strong>B:</strong> Students have had one of the largest changes to their lifestyle during this lockdown and
          have not had much government support at all so they have had to be so patient and strong-willed to continue to
          keep going which I admire them so much more. My advice is that we can just about see the light at the end of
          the tunnel (I really hope) and to just hold on as they have been doing so well. I have seen some wonderful and
          amazing things from student's weather it's helping out their community, doing shop runs for the elderly or
          vulnerable, donating to food banks, being essential works and even our medics and nurses taking time out to
          volunteer. It is important to stress also that it's okay not to be okay in these circumstances at times and to
          reach out to friends, family or other services to help them cope if need be.
        </p>

        <p>
          <strong>C:</strong> Honestly just keep going! I'm so lucky that I've still got my job and that keeps me in a
          really good routine during the week. I think there's a lot about people using this time to learn something new
          and do something to better themselves. If I'm honest, I think just getting through this in whatever way makes
          you happiest is the most important thing. Not everyone is going to be running a half marathon every day
          (trust me I'm not), and if reading a book or binging Netflix is what you want to do, then do it.
        </p>

        <p>
          Everyone is working so hard to create some sense of normality when we do return to campus, so even though next
          year might be different, there are still so many things to look forward to.
        </p>

        <h5 className="mb-4">
          8) What are your top 3 tips for next year's SU Officers?
        </h5>

        <p>
          <strong>A:</strong>

          <ul>
            <li>
              Enthusiastic – it's infectious!
            </li>
            <li>
              True to yourself – don't try to change who you are or what you do in order to gain favour; you'll earn
              more respect by being loyal to your values
            </li>
            <li>
              Fair – in everything you do, you are a representative of and a role model for ~34,000 students. Always be
              ready to justify how you're being equitable and to challenge inequalities with any one of these students.
            </li>
          </ul>
        </p>

        <p>
          <strong>B:</strong>

          <ul>
            <li>
              I would say firstly it's okay to ask for help. They will be starting in very unusual circumstances and
              therefore if they don't understand something and don't ask for help, it will only be of detriment to them
              and then the members they are representing. There is no such thing as silly questions and just really hope
              they are able to ask for help when needed.
            </li>
            <li>
              The second is to not try do everything at once and plan accordingly. You will have your manifesto, your
              remit of that officer role and other things which can seem like they all hit you at once so it's ever more
              important to plan your weeks and days as this makes like a lot more manageable.
            </li>
            <li>
              Lastly is be confident. You were elected because people have the confidence in you to deliver your
              manifesto pledge and therefore do your best to make it happen and most importantly enjoy it because time
              really does fly.
            </li>
          </ul>
        </p>

        <p>
          <strong>C:</strong>

          <ul>
            <li>
              Enjoy it – trust me its stressful 99% of the time but you will honestly never have a job like this again
              so make the most of it.
            </li>
            <li>
              Talk – everything is soooo much easier if you communicate with each other and the people around you.
              They'll be able to help you and you'll get so much more done in your year.
            </li>
            <li>
              Book your Annual Leave throughout the year! Otherwise you'll be like me taking weeks off during lockdown
              when there is literally nothing to do!
            </li>
          </ul>
        </p>
      </BlogPost>
    );
  }
}
