import React from 'react';

import Page from '../../page';

export default class PrivacyPolicy extends React.Component {

  render() {
    return (
      <Page>
        <article>
          <h1 className="mume-header" id="unisalad-privacy-policy">UniSalad Privacy Policy</h1>

          <p><em>Last revised: 10th May 2024</em></p>
          <hr />
          <p><strong>Offee Limited</strong> (“UniSalad” “we” “us” “our”) prioritize your privacy and are committed to protecting the personal information you share with us. This Privacy Policy provides detailed information about how we handle your personal data when you use our mobile application (the "App") and associated services.</p>

          <strong>Data Controller</strong>
          <p><strong>Offee Limited</strong>, a company incorporated in England and Wales (Company No. 13812521), is the data controller responsible for your personal data. Our registered office is located at:</p>
          <address>
            Hollywell Building<br />
            Holywell Way<br />
            Loughborough<br />
            England LE11 3UZ<br />
            Email: <a href="mailto:support@unisalad.co.uk">support@unisalad.co.uk</a>
          </address>

          <strong>Information We Collect:</strong>
          <ul>
            <li>Registration and Account Information: When you create an account, we collect your name, email address (university or personal), and date of birth.</li>
            <li>Profile Information: This includes information that helps personalize your user experience, such as your gender, profile pictures, and preferences.</li>
            <li>Communications with Us: We store records of your communication with us, including any customer support requests or feedback you submit.</li>
            <li>Technical and Usage Information: We collect information about your interaction with our App, such as IP addresses, device information, and browsing behaviour within the App.</li>
            <li>Third-Party Information: We may receive additional information about you from third-party partners, such as analytics services and advertising networks, which help us improve our services.</li>
          </ul>

          <strong>How We Use Your Information:</strong>
          <ul>
            <li>To Provide and Manage the App: We use your information to operate, maintain, and provide the features of the App, including customer support and personalized settings.</li>
            <li>To Improve Our Services: We analyse usage patterns and feedback to improve our App functionality and user experience.</li>
            <li>To Communicate With You: We use your contact information to communicate with you about your account and our services, and to send you promotional messages if you've opted in.</li>
            <li>To Ensure Security: We monitor our services to prevent, detect, and address technical issues, fraud, or other harmful activity.</li>
            <li>To Comply with Legal Obligations: We process your data when necessary to comply with legal obligations, such as regulatory requirements and tax laws.</li>
          </ul>

          <strong>Legal Bases for Processing</strong>
          <ul>
            <li>Consent: Specifically for marketing communications and certain cookies.</li>
            <li>Contract Performance: Necessary for the administration of your account with us.</li>
            <li>Legal Requirement: Compliance with laws, regulations, and sector recommendations.</li>
            <li>Legitimate Interests: For improving our services, preventing fraud, and ensuring the security of our app and the data it handles.</li>
          </ul>

          <strong>Sharing Your Information</strong>
          <p>We share information with third parties that help us operate and improve our services. These include:</p>
          <ul>
            <li>Service Providers: Companies that provide services on our behalf, such as data analysis, marketing assistance, email delivery, hosting services, and customer service.</li>
            <li>Legal Authorities: When required by law or as necessary to protect our rights.</li>
            <li>Business Transfers: In connection with a merger, acquisition, or sale of assets.</li>
          </ul>
          <p>We ensure all third parties respect the security of your personal data and treat it in accordance with the law.</p>

          <strong>International Data Transfers</strong>
          <p>Your information, including personal data, may be transferred to — and maintained on — computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those from your jurisdiction.</p>

          <strong>Your Rights</strong>
          <ul>
            <li>Access: The right to be provided with a copy of your personal data held by us.</li>
            <li>Rectification: The right to require us to correct any mistakes in your information.</li>
            <li>Erasure: The right to require us to delete your personal information.</li>
            <li>Restriction of Processing: The right to require us to restrict processing of your personal information.</li>
            <li>Data Portability: The right to receive the personal information you provided to us in a structured, commonly used, and machine-readable format and/or transmit that data to a third party.</li>
            <li>Objection: The right to object at any time to your personal information being processed for direct marketing (including profiling) or in certain other situations to our continued processing of your personal information.</li>
            <li>Withdrawal of Consent: The right to withdraw consent at any time (where applicable).</li>
          </ul>

          <strong>Data Security</strong>
          <p>We implement suitable security measures to prevent unauthorized access, disclosure, modification, or unauthorized destruction of the data.</p>

          <strong>Data Retention</strong>
          <p>We retain personal data for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>

          <strong>Changes to This Policy</strong>
          <p>We may update this policy from time to time by publishing a new version on our website. We will notify you of significant changes.</p>
        </article>
      </Page>
    );
  }
}
