import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import AppScroller from './app-scroller';

import About from './pages/about';
import Acknowledgements from './pages/acknowledgements';
import Apply from './pages/apply';
import Blog from './pages/blog';
import Contact from './pages/contact';
import Faq from './pages/faq';
import PrivacyPolicy from './pages/privacy-policy';
import Promotions from './pages/promotions';
import TermsOfService from './pages/terms-of-service';

import {
  Blog1,
  Blog2,
  Blog3,
  Blog4,
  Blog5,
} from './blogs';

const pages = [
  {
    path: '/about',
    component: <About />,
  },
  {
    path: '/acknowledgements',
    component: <Acknowledgements />,
  },
  {
    path: '/apply',
    component: <Apply />,
  },
  {
    path: '/blog',
    component: <Blog />,
  },
  {
    path: '/contact',
    component: <Contact />,
  },
  {
    path: '/faq',
    component: <Faq />,
  },
  {
    path: '/privacy-policy',
    component: <PrivacyPolicy />,
  },
  {
    path: '/promotions',
    component: <Promotions />,
  },
  {
    path: '/terms-of-service',
    component: <TermsOfService />,
  },
];

const blogs = [
  {
    path: '/blog/1',
    component: <Blog1 />,
  },
  {
    path: '/blog/2',
    component: <Blog2 />,
  },
  {
    path: '/blog/3',
    component: <Blog3 />,
  },
  {
    path: '/blog/4',
    component: <Blog4 />,
  },
  {
    path: '/blog/5',
    component: <Blog5 />,
  },
];

export default class App extends React.Component {

  render() {
    return (
      <BrowserRouter>
        <Switch>
          {pages.map((route) => <Route exact path={route.path}>{route.component}</Route>)}
          {blogs.map((route) => <Route exact path={route.path}>{route.component}</Route>)}
          <Route exact path="/">
            <AppScroller />
          </Route>
        </Switch>
      </BrowserRouter>
    );
  }
}
