import React from 'react';

import Page from '../../page';

export default class About extends React.Component {

  render() {
    return (
      <Page>
        <h1>About</h1>

        <p>
        UniSalad started in 2013 as a Facebook Group for Nottingham University students to buy and sell unwanted tickets for nights out. Founder & Director, Rebecca De Beukelaer, created the group whilst studying at the University of Nottingham.
        </p>

        <p>
        Over the years, the group grew, and became a place for general university chatter, to look for lost and found items, hunt for new housemates, carpooling and sell unwanted bus and train tickets. After five years of rapid organic growth of more than 35,000 Nottingham students and huge popularity, the Facebook platform showed limitations to the group’s potential, and its founder decided to transform the group into something more secure, easy to use and valuable. In January 2019, the UniSalad app was born.
        </p>

        <p>UniSalad is a student-only platform where users can connect with other students from the same university. The app is made up of the following sections:
          <ul>
            <li><strong>UniChat</strong>: a feed where students can share views, stories, events and advice.</li>
            <li><strong>Confessions</strong>: to post your anonymous confessions and see what’s buzzing at your uni.</li>
            <li><strong>Buy & Sell</strong>: to sell anything from old books to unwanted tickets for nights out which includes unwanted bus or train tickets.</li>
            <li><strong>Housing</strong>: to look for new housemates, or advertise a spare student room.</li>
            <li><strong>Lost & Found</strong>: for students to reunite themselves with misplaced belongings</li>
          </ul>
        </p>

        <p>UniSalad provides a useful and simple student app which is now available to all UK university students.</p>
      </Page>
    );
  }
}
