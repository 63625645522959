import React from 'react';

import Page from '../../page';

export default class Faq extends React.Component {

  render() {
    return (
      <Page>
        <h1>FAQ</h1>

        <strong>Great news for our UniSalad community! We've updated our ticket reselling policy to better reflect our belief in a free market. UniSalad now allows you to resell tickets at market-driven prices, giving everyone the freedom to determine the value of their tickets based on demand.</strong>

        <p>Here’s what you need to know:</p>
        <p>Free Market Approach: You have the autonomy to set ticket prices that reflect current market conditions. While we recommend keeping resale prices reasonable—ideally not exceeding 1.5 times the original ticket price—we support your right to get fair market value for tickets you can no longer use.</p>
        <p>Responsibility: Remember to check if the specific event or club allows reselling tickets above the original price. We encourage you to verify this by visiting the event’s official website, checking their social media pages, or contacting them directly.</p>
        <p>Community Consideration: UniSalad is committed to fostering a supportive and fair trading environment. While we embrace a free market approach, we trust you to make pricing decisions that are considerate of the common budget constraints faced by students.</p>
        <p>UniSalad is here to support your freedom to buy and sell while providing a platform that helps everyone involved. Let's embrace these new opportunities responsibly and continue to support each other through our community-driven marketplace.</p>
        <strong>Thank you for choosing UniSalad, where we believe in empowering students with more flexibility and control over their transactions! </strong>
      </Page>
    );
  }
}
