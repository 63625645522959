import React from 'react';

import Page from '../../page';

export default class Promotions extends React.Component {

  render() {
    return (
      <Page>
        <h1>Promotions</h1>

        {/* <h2>Facebook Promotional Launch (Closing 23<sup>rd</sup> January 2019) Terms & Conditions</h2> */}

        <strong>Thank you for checking out our promotions! We are not running any promotions at the moment, but please stay tuned. We frequently update this section with exciting offers and deals. Check back soon for any updates, and we look forward to bringing you more ways to save and enjoy our services!</strong>

        {/* <p>
          <ol>
            <li>The promoter is: UniSalad Ltd (company no. 09669396) whose registered office
            is at
            15 High Street, Lydney, Gloucester, United Kingdom, GL15 5DP.</li>
            <li>The competition is open to residents of the United Kingdom aged 18 years or
            over and a student studying at a university or college.</li>
            <li>There is no entry fee and no purchase necessary to enter this competition.</li>
            <li>By entering this competition, an entrant is indicating his/her agreement to be
            bound by these terms and conditions.</li>
            <li>How to enter into the competition: Comment the word "UniSalad" on our
            Facebook group pinned post promo video to be entered into the draw.</li>
            <li>The competition is to promote the UniSalad App. You can find more information
            via our website: <a href="https://www.unisalad.com/">https://www.unisalad.com</a></li>
            <li>Only one entry will be accepted per person. Multiple entries from the same
            person will be disqualified.</li>
            <li>Closing date for entry will be 23rd January 2019. After this date the no further
            entries to the competition will be permitted.</li>
            <li>No responsibility can be accepted for entries not received for whatever reason.</li>
            <li>The promoter reserves the right to cancel the competition if circumstances
            arise outside of its control. Any changes to the competition will be notified to
            entrants as soon as possible by the promoter.</li>
            <li>The promoter is not responsible for inaccurate prize details supplied to any
            entrant by any third party connected with this competition.</li>
            <li>The prize is as follows:
              <ul>
                <li>5 VIP tickets for the UniSalad launch party @ Crisis All-Nighter Wed 30 Jan 2019</li>
                <li>3 700ml bottles of spirits</li>
                <li>4 1L bottles of mixers</li>
                <li>5 packs of snacks</li>
                <li>1 UniSalad snapback & 1 UniSalad T-shirt</li>
              </ul>

              <p>
                The prize is as stated and no cash or other alternatives will be offered. The prizes
                are not transferable. Prizes are subject to availability and we reserve the right to
                substitute any prize with another of equivalent value without giving notice.
              </p>
            </li>
            <li>Winners will be chosen at random by software, from all entries received and
            verified by Promoter and or its agents.</li>
            <li>The winner will be notified by DM via Facebook on 23rd January 2019 by the
            group admin, Rebecca De Beukelaer. If the winner cannot be contacted or do
            not claim the prize within 7 days of notification, we reserve the right to
            withdraw the prize from the winner and pick a replacement winner.</li>
            <li>The promoter will notify the winner when and where the prize can be
            collected (this will be on the university of Nottingham campus).</li>
            <li>The promoter's decision in respect of all matters to do with the competition
            will be final and no correspondence will be entered into.</li>
            <li>By entering this competition, an entrant is indicating his/her agreement to be
            bound by these terms and conditions.</li>
            <li>The competition and these terms and conditions will be governed by English
            law and any disputes will be subject to the exclusive jurisdiction of the courts
            of England.</li>
            <li>The winner agrees to the use of his/her name and image in any publicity
            material, as well as their entry. Any personal data relating to the winner or any
            other entrants will be used solely in accordance with current UK data
            protection legislation and will not be disclosed to a third party without the
            entrant's prior consent.</li>
            <li>The winner's name will be available 7 days after closing date by emailing the
            following address: support@unisalad.co.uk</li>
            <li>Entry into the competition will be deemed as acceptance of these terms and
            conditions.</li>
            <li>This promotion is in no way sponsored, endorsed or administered by, or
            associated with, Facebook, Twitter or any other Social Network. You are
            providing your information to UniSalad and not to any other party. The
            information provided will be used in conjunction with the following Privacy
            Policy found at <a href="https://www.unisalad.com/privacy-policy">https://www.unisalad.com/privacy-policy</a></li>
          </ol>
        </p> */}
      </Page>
    );
  }
}
