import React from 'react';
import { Link } from 'react-router-dom';

import Page from '../../page';

export default class Blog extends React.Component {

  render() {
    return (
      <Page>
        <h1 style={{ marginBottom: '40px' }}>UniSalad Blog</h1>

        <div style={{ marginBottom: '24px', padding: '8px', border: '1px solid rgba(172, 148, 148, 0.5)', borderRadius: '8px' }}>
          <h2><Link to="/blog/5" style={{ color: 'rgb(172, 148, 148)' }}>Interview with your current SU officers</Link></h2>

          <p style={{ marginBottom: '0px' }}>
            We spoke to three of your current Officers: on their experiences so far, dealing with sudden university
            closure, tips to stay positive during the rest of lockdown and advice to next year's elected Officers.
          </p>
        </div>

        <div style={{ marginBottom: '24px', padding: '8px', border: '1px solid rgba(172, 148, 148, 0.5)', borderRadius: '8px' }}>
          <h2><Link to="/blog/4" style={{ color: 'rgb(172, 148, 148)' }}>Interview with your SU Officers candidates</Link></h2>

          <p style={{ marginBottom: '0px' }}>
            We caught up with some of the candidates running for SU Officer positions this week, giving insights into
            their campaigns, thoughts on life at uni next term, and advice under lockdown!
          </p>
        </div>

        <div style={{ marginBottom: '24px', padding: '8px', border: '1px solid rgba(172, 148, 148, 0.5)', borderRadius: '8px' }}>
          <h2><Link to="/blog/3" style={{ color: 'rgb(172, 148, 148)' }}>Interview with The NottsFessional</Link></h2>

          <p style={{ marginBottom: '0px' }}>
            We caught up with the mystery admin behind NottsFessional on how they manage the page and coping strategies
            under Covid-19
          </p>
        </div>

        <div style={{ marginBottom: '24px', padding: '8px', border: '1px solid rgba(172, 148, 148, 0.5)', borderRadius: '8px' }}>
          <h2><Link to="/blog/2" style={{ color: 'rgb(172, 148, 148)' }}>Interview with CRISIS owner: Andrew Smith</Link></h2>

          <p style={{ marginBottom: '0px' }}>
            Coping with a sudden closure, re-opening plans and advice to students struggling
          </p>
        </div>

        <div style={{ marginBottom: '24px', padding: '8px', border: '1px solid rgba(172, 148, 148, 0.5)', borderRadius: '8px' }}>
          <h2><Link to="/blog/1" style={{ color: 'rgb(172, 148, 148)' }}>UniSalad's new mission under Covid-19</Link></h2>

          <p style={{ marginBottom: '0px' }}>A note from our founder</p>
        </div>
      </Page>
    );
  }
}
