import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import logo from '../assets/img/logo/ss-logo-138x33.png';
import logo2x from '../assets/img/logo/ss-logo-138x33.png';

export default class Page extends React.Component {

  render() {
    return [
      <nav id="navbar" className="navbar navbar-expand-lg navbar-light" style={{ boxShadow: 'none', backgroundColor: '#f9f9f9' }}>
        <div className="container">
          <Link className="navbar-brand mx-0 mx-lg-3" to="/">
            <img className="align-middle mr-2"
              src={logo}
              srcSet={`${logo} 1x, ${logo2x} 2x`}
              width="138" height="33" alt="" />
          </Link>

          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerContent" aria-controls="navbarTogglerContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse mx-0 mx-lg-3" id="navbarTogglerContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item mx-2">
                <Link className="nav-link" to="/">App</Link>
              </li>

              <li className="nav-item mx-2">
                <Link className="nav-link" to="/blog">Blog</Link>
              </li>

              <li className="nav-item mx-2">
                <Link className="nav-link" to="/about">About Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      ,
      <section className="us-section us-alternate">
        <div className="container">

          <div className="mb-4 d-flex">
            <div className="flex-fill">
              <div className="p-4" style={{ backgroundColor: 'white', borderRadius: '5px' }}>
                {this.props.children}
              </div>
            </div>

            <div className="ml-3 d-none d-lg-block" style={{ minWidth: '240px' }}>
              <div className="mb-4 p-4" style={{ backgroundColor: 'white', borderRadius: '5px' }}>
                <h5 className="mb-4">Recent Blog Posts</h5>

                <div className="mb-2 pb-2" style={{ color: 'rgb(172, 148, 148)' }}>
                  <NavLink exact activeStyle={{ color: 'rgb(38, 40, 47)' }} to="/blog/5">Interview with your current SU officers</NavLink>
                </div>

                <div className="mb-2 pb-2" style={{ color: 'rgb(172, 148, 148)' }}>
                  <NavLink exact activeStyle={{ color: 'rgb(38, 40, 47)' }} to="/blog/4">Interview with your SU Officers candidates</NavLink>
                </div>

                <div className="mb-2 pb-2" style={{ color: 'rgb(172, 148, 148)' }}>
                  <NavLink exact activeStyle={{ color: 'rgb(38, 40, 47)' }} to="/blog/3">Interview with The NottsFessional</NavLink>
                </div>

                <div className="mb-2 pb-2" style={{ color: 'rgb(172, 148, 148)' }}>
                  <NavLink exact activeStyle={{ color: 'rgb(38, 40, 47)' }} to="/blog/2">Interview with CRISIS owner: Andrew Smith</NavLink>
                </div>

                <div className="mb-2 pb-2" style={{ color: 'rgb(172, 148, 148)' }}>
                  <NavLink exact activeStyle={{ color: 'rgb(38, 40, 47)' }} to="/blog/1">UniSalad's new mission under Covid-19</NavLink>
                </div>
              </div>

              <div className="p-4" style={{ backgroundColor: 'white', borderRadius: '5px' }}>
                <div className="mb-2 pb-2" style={{ color: 'rgb(172, 148, 148)' }}>
                  <NavLink exact activeStyle={{ color: 'rgb(38, 40, 47)' }} to="/about">About Us</NavLink>
                </div>

                <div className="mb-2 pb-2" style={{ color: 'rgb(172, 148, 148)' }}>
                  <NavLink exact activeStyle={{ color: 'rgb(38, 40, 47)' }} to="/blog">Blog</NavLink>
                </div>

                <div className="mb-2 pb-2" style={{ color: 'rgb(172, 148, 148)' }}>
                  <NavLink exact activeStyle={{ color: 'rgb(38, 40, 47)' }} to="/promotions">Promotions</NavLink>
                </div>

                <div className="mb-2 pb-2" style={{ color: 'rgb(172, 148, 148)' }}>
                  <NavLink exact activeStyle={{ color: 'rgb(38, 40, 47)' }} to="/faq">FAQ</NavLink>
                </div>

                <div className="mb-2 pb-2" style={{ color: 'rgb(172, 148, 148)' }}>
                  <NavLink exact activeStyle={{ color: 'rgb(38, 40, 47)' }} to="/privacy-policy">Privacy Policy</NavLink>
                </div>

                <div className="mb-2 pb-2" style={{ color: 'rgb(172, 148, 148)' }}>
                  <NavLink exact activeStyle={{ color: 'rgb(38, 40, 47)' }} to="/terms-of-service">Terms of Service</NavLink>
                </div>

                <div className="mt-2 pb-2" style={{ color: 'rgb(172, 148, 148)' }}>
                  <NavLink exact activeStyle={{ color: 'rgb(38, 40, 47)' }} to="/contact">Contact Us</NavLink>
                </div>

                <div className="mt-2 pb-2" style={{ color: 'rgb(172, 148, 148)' }}>
                  <NavLink exact activeStyle={{ color: 'rgb(38, 40, 47)' }} to="/apply">Apply</NavLink>
                </div>

                <hr />

                <div className="mt-2 pb-2" style={{ color: 'rgb(172, 148, 148)' }}>
                  <NavLink exact activeStyle={{ color: 'rgb(38, 40, 47)' }} to="/acknowledgements">Acknowledgements</NavLink>
                </div>
              </div>
            </div>
          </div>

          <div className="d-block d-lg-none">
            <div className="mb-4 p-4" style={{ backgroundColor: 'white', borderRadius: '5px' }}>
              <h5 className="mb-4">Recent Blog Posts</h5>

              <div className="mb-2 pb-2" style={{ color: 'rgb(172, 148, 148)' }}>
                <NavLink exact activeStyle={{ color: 'rgb(38, 40, 47)' }} to="/blog/5">Interview with your current SU officers</NavLink>
              </div>

              <div className="mb-2 pb-2" style={{ color: 'rgb(172, 148, 148)' }}>
                <NavLink exact activeStyle={{ color: 'rgb(38, 40, 47)' }} to="/blog/4">Interview with your SU Officers candidates</NavLink>
              </div>

              <div className="mb-2 pb-2" style={{ color: 'rgb(172, 148, 148)' }}>
                <NavLink exact activeStyle={{ color: 'rgb(38, 40, 47)' }} to="/blog/3">Interview with The NottsFessional</NavLink>
              </div>

              <div className="mb-2 pb-2" style={{ color: 'rgb(172, 148, 148)' }}>
                <NavLink exact activeStyle={{ color: 'rgb(38, 40, 47)' }} to="/blog/2">Interview with CRISIS owner: Andrew Smith</NavLink>
              </div>

              <div className="mb-2 pb-2" style={{ color: 'rgb(172, 148, 148)' }}>
                <NavLink exact activeStyle={{ color: 'rgb(38, 40, 47)' }} to="/blog/1">UniSalad's new mission under Covid-19</NavLink>
              </div>
            </div>

            <div className="p-4" style={{ backgroundColor: 'white', borderRadius: '5px' }}>
              <div className="mb-2 pb-2" style={{ color: 'rgb(172, 148, 148)' }}>
                <NavLink exact activeStyle={{ color: 'rgb(38, 40, 47)' }} to="/about">About Us</NavLink>
              </div>

              <div className="mb-2 pb-2" style={{ color: 'rgb(172, 148, 148)' }}>
                <NavLink exact activeStyle={{ color: 'rgb(38, 40, 47)' }} to="/blog">Blog</NavLink>
              </div>

              <div className="mb-2 pb-2" style={{ color: 'rgb(172, 148, 148)' }}>
                <NavLink exact activeStyle={{ color: 'rgb(38, 40, 47)' }} to="/promotions">Promotions</NavLink>
              </div>

              <div className="mb-2 pb-2" style={{ color: 'rgb(172, 148, 148)' }}>
                <NavLink exact activeStyle={{ color: 'rgb(38, 40, 47)' }} to="/faq">FAQ</NavLink>
              </div>

              <div className="mb-2 pb-2" style={{ color: 'rgb(172, 148, 148)' }}>
                <NavLink exact activeStyle={{ color: 'rgb(38, 40, 47)' }} to="/privacy-policy">Privacy Policy</NavLink>
              </div>

              <div className="mb-2 pb-2" style={{ color: 'rgb(172, 148, 148)' }}>
                <NavLink exact activeStyle={{ color: 'rgb(38, 40, 47)' }} to="/terms-of-service">Terms of Service</NavLink>
              </div>

              <div className="mt-2 pb-2" style={{ color: 'rgb(172, 148, 148)' }}>
                <NavLink exact activeStyle={{ color: 'rgb(38, 40, 47)' }} to="/contact">Contact Us</NavLink>
              </div>

              <div className="mt-2 pb-2" style={{ color: 'rgb(172, 148, 148)' }}>
                <NavLink exact activeStyle={{ color: 'rgb(38, 40, 47)' }} to="/apply">Apply</NavLink>
              </div>

              <hr />

              <div className="mt-2 pb-2" style={{ color: 'rgb(172, 148, 148)' }}>
                <NavLink exact activeStyle={{ color: 'rgb(38, 40, 47)' }} to="/acknowledgements">Acknowledgements</NavLink>
              </div>
            </div>
          </div>

        </div>
      </section>
      ,
      <footer key="footer" className="footer">
        <div className="container">
          <div className="mb-4 d-flex justify-content-between">
            <div className="d-flex flex-column flex-lg-row">
              <div style={{ minWidth: '200px' }}>
                <div className="mb-1">
                  <Link to="/about">About Us</Link>
                </div>
                <div className="mb-1">
                  <Link to="/blog">Blog</Link>
                </div>
                <div className="mb-1">
                  <Link to="/promotions">Promotions</Link>
                </div>
                <div className="mb-1">
                  <Link to="/faq">FAQ</Link>
                </div>
              </div>

              <div style={{ minWidth: '200px' }}>
                <div className="mb-1">
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </div>
                <div className="mb-1">
                  <Link to="/terms-of-service">Terms of Service</Link>
                </div>
                <div className="mb-1">
                  <Link to="/acknowledgements">Acknowledgements</Link>
                </div>
              </div>

              <div style={{ minWidth: '200px' }}>
                <div className="mb-1">
                  <Link to="/apply">Apply</Link>
                </div>
                <div className="mb-1">
                  <Link to="/contact">Contact</Link>
                </div>
              </div>
            </div>

            <div>
              <a href="https://www.facebook.com/unisaladapp/" target="_blank" rel="noopener noreferrer" className="px-2">
                <i className="fab fa-lg fa-fw fa-facebook"></i>
              </a>
              <a href="https://twitter.com/unisalad/" target="_blank" rel="noopener noreferrer" className="px-2">
                <i className="fab fa-lg fa-fw fa-twitter"></i>
              </a>
              <a href="https://www.instagram.com/uni_salad/" target="_blank" rel="noopener noreferrer" className="px-2">
                <i className="fab fa-lg fa-fw fa-instagram"></i>
              </a>
            </div>
          </div>
          <p style={{ fontSize: '0.8rem' }}>© 2024 OFFEE Ltd. All rights reserved</p>
        </div>
      </footer>
    ];
  }
}
