import React from 'react';

import BlogPost from './blog-post';

export default class Blog3 extends React.Component {

  render() {
    return (
      <BlogPost>
        <h1 style={{ marginBottom: '16px' }}>Interview with The NottsFessional</h1>

        <h3 style={{ marginBottom: '24px' }}>
          We caught up with the mystery admin behind NottsFessional on how they manage the page and coping strategies
          under Covid-19
        </h3>

        <h5 className="mb-4">A bit of background... When did you start The Nottsfessional?</h5>

        <p>
          We started the original page in November 2018 but it didn't last very long! This current page was made May
          2019 but didn't get properly used until January 2020.
        </p>

        <h5 className="mb-4">How many posts on average do you get a day?</h5>

        <p>
          I'd say probably around 80.
        </p>

        <h5 className="mb-4">How much time do you spend managing it?</h5>

        <p>
          Probably a few hours a day! I'm currently working so all posts are scheduled, but I still have to manually
          read each submission and make sure it adheres to the page rules (don't want to risk the page being shut down
          again!!) and also respond to messages.
        </p>

        <h5 className="mb-4">Is it managed by one admin or multiple?</h5>

        <p>
          There's one "full-time" admin (me) and a few other people that check the submissions when they can! But it's
          just me who comments/messages.
        </p>

        <h5 className="mb-4">Can you comment on the other NottsFess pages which existed and got shutdown?</h5>

        <p>
          Yes, so the first page was us (same admin team) and it either got shut down for violating community
          guidelines (we stupidly posted some dodgy submissions) OR *conspiracy theory* was reported by the makers of
          the NottsFess page (they messaged us trying to buy this page off us! <span role="img" aria-label="smile">😂</span>). All pages with the name
          "The Nottsfessional" and the same page icon have been the original admin team though.
        </p>

        <h5 className="mb-4">What will happen to the page when you graduate?</h5>

        <p>
          I saw a submission where someone suggested a survey type thing so we could find suitable people to pass the
          page over to! I know other confessions pages have done the same too so we'll probably do that.
        </p>

        <h5 className="mb-4">Have you enjoyed doing this so far?</h5>

        <p>
          Yeah, it's been really fun! It's so entertaining reading the submissions and messaging people/commenting.
          It's nice having this little "admin" (or admum, but I would like to state on the record that I did not
          suggest or advocate for admum. I just said I preferred it to admin).
        </p>

        <h5 className="mb-4">Moving onto life under Covid-19... How have you personally been coping?</h5>

        <p>
          It's ok... I suffer with my own mental health, so I've found it really important to keep busy! I work four
          days a week in a pharmacy and then spend the rest of my time doing my uni work and, honestly, it is
          stressful and a lot of work but for me personally I need that, rather than being stuck in the house all day
          every day!
        </p>

        <h5 className="mb-4">Have you gone home or had to stay isolated at Uni?</h5>

        <p>I've gone home!</p>

        <h5 className="mb-4">
          We've seen a lot of mental health related posts published on the page recently (for obvious reasons). Have
          you seen an increase in these sorts of confessions being sent to you?
        </h5>

        <p>
          I wouldn't necessarily say they've increased but yeah we do tend to get quite a lot of MH posts. We try and
          post as many as we can - ones offering or asking for advice mainly. We can't post ones though that insinuate
          harm to themselves/others or also could possibly be triggering for other people. Thankfully, we don't get
          many posts like that but if we ever do we like to highlight/share some of the resources available to
          students who may be struggling.
        </p>

        <h5 className="mb-4">
          Do you think anything can be done to help student Mental Health? Either from the university or student
          groups?
        </h5>

        <p>
          I think a lot more can be done for MH by EVERYONE. There's still unfortunately a lot of stigma behind it
          which means it can be really difficult for people to get help/diagnosed in the first place. On top of that,
          you have the difficulty of getting help and maintaining that with the MH services being under so much
          stress.
        </p>

        <p>
          Like I said, as a page we like to share some of the Uni resources where we can (and we have links/numbers on
          our pinned post) but I also think the University/SU could do more, at the very least just to make the
          process a bit more transparent/easy to navigate. Especially in these difficult times with everything being
          online - I know online counselling is still available but I'm not sure whether many other students know
          about that.
        </p>

        <h5 className="mb-4">
          Any word of advice for students during this delicate time? Can you share any tips or tricks to stay sane and
          positive?
        </h5>

        <p>
          Just to stay safe! Look after each other, check up on your friends, and respect your key workers/retail
          staff (that last one is more of a personal request).
        </p>
      </BlogPost>
    );
  }
}
