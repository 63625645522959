import React from 'react';

import BlogPost from './blog-post';

export default class Blog1 extends React.Component {

  render() {
    return (
      <BlogPost>
        <h1 style={{ marginBottom: '16px' }}>UniSalad's new mission under Covid-19</h1>

        <h3 style={{ marginBottom: '24px' }}>A note from our founder</h3>

        <p>
          Covid-19 has created a sudden halt our day to day life. The world is at a standstill, death tolls are sadly
          very high, and the future of the economy is uncertain. Our mental health has been greatly affected and most
          people don't know what the post-Covid world looks like.
        </p>

        <p>
          Universities, like many other establishments, have shut. Students are home and isolated from peers and their
          university community. During this time, it's important to look after ourselves and each other, and keep up
          good spirits.
        </p>

        <p>
          We believe that it's important to try and share happiness and fun through our app and keep the student
          community strong. If UniSalad can't be used for its original purpose, then we should try and use it for
          something else which will benefit students.
        </p>

        <p>
          We have carried out student surveys on how UniSalad should be used whilst everyone is home. The idea of
          pivoting the app to an ‘information, wellbeing and entertainment' platform has been popular amongst
          feedback.
        </p>

        <p>
          We have therefore decided to provide content for the following:

          <ul>
            <li>Info: University information & updates on new measures for online learning etc</li>
            <li>Wellbeing: tips, tricks and stories to maintain good mental health</li>
            <li>Wellbeing on how to: how to not get homesick if quarantined in Notts, how to survive living at your parent's house, how to structure your day</li>
            <li>Health: workout routines links to stay in shape</li>
            <li>Entertainment: fun student updates / stories or memes</li>
            <li>Entertainment: exclusive interviews with club owners / SU representatives</li>
            <li>Entertainment: Games / quizzes</li>
            <li>Entertainment: Competitions to win fun items to keep you sane during isolation</li>
            <li>Info: Information from businesses in Notts & student deals ready for when you come back to Notts</li>
          </ul>
        </p>

        <p>
          We will be pushing at least one type of content a day Monday to Friday to keep you up to date, sane and
          entertained. Feel free to post something yourself, we would love for you to join in!
        </p>

        <p>
          We hope you enjoy the content, and make sure to stay safe.
        </p>

        <p>
          Rebecca
        </p>
      </BlogPost>
    );
  }
}
