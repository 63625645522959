import React from 'react';

import Page from '../../page';

export default class TermsOfService extends React.Component {

  render() {
    return (
      <Page>
        <article>
          <h1 className="mume-header" id="unisalad-terms-of-service">UniSalad Terms of Service</h1>

          <p>This published copy of the terms of service is effective 10th May 2024. By using our services on or after 10th May 2024, you agree to these terms of service.</p>
          <p><em>Last revised: 10th May 2024</em></p>
          <hr />
          <p>These Terms of Service govern the use of the mobile App offered by Offee Limited (&quot;we&quot;, &quot;us&quot;, &quot;our&quot; or &quot;UniSalad&quot;).</p>
          <p>These Terms of Service are a legally binding agreement between you and us. By installing the App, you agree to these Terms of Service. If you do not agree to these Terms of Service, do not install the App.</p>
          <h2 className="mume-header" id="1-information-about-us">1. Information about us</h2>

          <p>Offee Limited is a registered company incorporated in England and Wales.</p>
          <ul>
            <li>Company number: 13812521</li>
            <li>Registered office address: Holywell Building, Hollywell Way, Loughborough, England, LE11 3UZ</li>
            <li>Contact email address: <a href="mailto:support@unisalad.co.uk" style={{ color: 'blue', textDecoration: 'underline' }}>support@unisalad.co.uk</a></li>
          </ul>
          <h2 className="mume-header" id="2-the-app">2. The App</h2>

          <p>The App (and any updates or supplements to it) allows students to connect with other students from the same university through their mobile devices. The App offers a service where students can buy and sell second hand items, event and travel tickets, post about and find lost and found property, advertise spare rooms in student houses, post anonymously and communicate with each other.</p>
          <p>UniSalad licences you to use the App and any updates or supplements to it as permitted in these Terms of Service.</p>
          <p>In order to register and utilise the App, you must be a current student at a university or college in
            the UK and possess a valid student email address ending in &quot;<a href="http://ac.uk">ac.uk</a>&quot;.
            The &quot;<a href="http://ac.uk">ac.uk</a>&quot; email address will be used in our verification processes
            when your account is set up.</p>
          <p>When creating your account, you must provide accurate and true information, and you are responsible for
            updating it either through your profile or by notifying us, throughout the time you use the App. You may
            not register more than one account.</p>
          <h2 className="mume-header" id="3-your-privacy">3. Your Privacy</h2>

          <p>Under data protection legislation, we are required to provide you with certain information about who we are, how we process your personal data and for what purposes and your rights in relation to your personal data and how to exercise them. This information is provided in our privacy policy (found at the bottom of our website under 'Privacy Policy' and on the app via your profile under Privacy Policy) and it is important that you read that information.</p>
          <h2 className="mume-header" id="4-operating-system-requirements">4. Operating System Requirements</h2>

          <p>The App requires a IOS or Android mobile device with minimum operating system version of Android 5.0 or IOS 17.0.</p>
          <h2 className="mume-header" id="5-support-for-the-app-and-how-to-tell-us-about-problems">5. Support For The App
            And How To Tell Us About Problems</h2>

          <ul>
            <li>Support: If you want to learn more about the App or the Service or have any problems using them
              please take a look at our support resources at <a href="https://www.unisalad.com" style={{ color: 'blue', textDecoration: 'underline' }}>unisalad.com</a>.</li>
            <li>Contacting us (including with complaints): If you think the App is faulty or misdescribed or wish to
              contact us for any other reason please email our customer service team at <a href="mailto:support@unisalad.co.uk" style={{ color: 'blue', textDecoration: 'underline' }}>support@unisalad.co.uk</a>.</li>
            <li>How we will communicate with you: If we have to contact you we will do so by email using the contact
              details you have provided to us.</li>
          </ul>
          <h2 className="mume-header" id="6-how-you-may-use-the-app">6. How You May Use The App</h2>

          <p>In return for your agreeing to comply with these Terms of Service, you may:</p>
          <ul>
            <li>download a copy of the App and view, use and display the App on such devices for your personal
              purposes only; and</li>
            <li>receive and use any free supplementary software code or update of the App incorporating
              &quot;patches&quot; and corrections of errors as we may provide to you.</li>
          </ul>
          <p>You must be aged 16 or over to accept these Terms of Service and install the App.</p>
          <p>You may not transfer the App to someone else. If you sell or give away any device on which the App is
            installed, you must remove the App from it.</p>
          <p>If you download the App onto any phone or other device not owned by you, you must have the owner&apos;s
            permission to do so. You will be responsible for complying with these Terms of Service, whether or not
            you own the phone or other device.<br />
            By using the App, you agree to us collecting and using technical information about the devices you use
            the App on and related software, hardware and peripherals to improve our products and to provide any
            services to you.</p>
          <p>You agree that you will:</p>
          <ul>
            <li>not rent, lease, sub-license, loan, provide or otherwise make available, the App in any form, in
              whole or in part to any person without prior written consent from us;</li>
            <li>not copy the App except as part of the normal use of the App;</li>
            <li>not translate, merge, adapt, vary, alter or modify, the whole or any part of the App, nor permit the
              App or any part of it to be combined with, or become involved in, any other programs, except as
              necessary to use the App as permitted in these Terms of Service;</li>
            <li>not disassemble, de-compile, reverse engineer or create derivative works based on the whole or any
              part of the App, nor attempt to do such things, except to the extent that (by virtue of sections 50B
              and 296A of the Copyright, Designs and Patents Act 1988) such actions cannot be prohibited because they
              are necessary to decompile the App to obtain the information necessary to create an independent program
              that can be operated with the App or with another program (Permitted Objective), and provided that the
              information obtained by you during such activities:
              <ul>
                <li>is not disclosed or communicated without our prior written consent to any third party to whom it
                  is not necessary to disclose or communicate it in order to achieve the Permitted Objective; and</li>
                <li>is not used to create any software that is substantially similar in its expression to the App;</li>
                <li>is kept secure; and</li>
                <li>is used only for the Permitted Objective; and</li>
                <li>comply with all applicable technology control or export laws and regulations that apply to the
                  technology used or supported by the App or any Service.</li>
              </ul>
            </li>
          </ul>
          <h2 className="mume-header" id="7-disclaimer">7. Disclaimer</h2>

          <p>We’re excited to announce the integration of PayPal's services to enhance transaction security. When you choose to use PayPal for transactions on UniSalad, you gain the benefits of PayPal's Buyer and Seller Protection programs. This means:</p>
          <p>Buyer Protection: If an item doesn’t arrive or isn’t as described, PayPal can help you get a full refund.
            Seller Security: Sell with confidence knowing that PayPal offers protection against false claims and chargebacks.
            This addition is part of our commitment to enhancing user security and providing peace of mind.
          </p>
          <p>Responsibility and Disputes</p>
          <p>UniSalad is not responsible for any transfer of payments, deliveries, collections, transfers of files, and services that may follow conversations or communications on the App between two users. It is the sole responsibility of the two parties involved in the discussions to ensure a smooth and efficient transaction. Unless otherwise specified in these Terms of Service, we are not responsible for (and make no promises or representations as to) the behavior, acts, or omissions of any users with whom you may engage through the App. Nor are we responsible for (and we make no promises or representations in respect of) the quality of any goods or services they may provide. Any disputes between users should be settled between the two parties. If you have any complaint towards a specific user that cannot be resolved by you and that user, you may report the user to us (using the reporting features within the App, or by sending us feedback via the Contact Us section within the App). We may agree to try to resolve the problem to the extent that we can, but we cannot guarantee that we will resolve any such problems or disputes.</p>
          <p>App Availability</p>
          <p>UniSalad provides the App on an "as is" and "as available" basis without any promises or representations, express or implied.</p>

          <h2 className="mume-header" id="8-acceptable-use-restrictions">8. Acceptable Use Restrictions</h2>

          <p>You must take the full responsibility for posts that are created by you or your device.</p>
          <p>You must:</p>
          <ul>
            <li>not use the App in any unlawful manner, for any unlawful purpose, or in any manner inconsistent with
              these Terms of Service, or act fraudulently or maliciously, for example, by hacking into or inserting
              malicious code, such as viruses, or harmful data, into the App or any operating system;</li>
            <li>not infringe our intellectual property rights or those of any third party in relation to your use of
              the App;</li>
            <li>not transmit any material that is defamatory, obscene, offensive or otherwise objectionable in
              relation to your use of the App;</li>
            <li>not use the App in any way that promotes discrimination based on race, sex, religion, nationality,
              disability, sexual orientation or age;</li>
            <li>not use the App in any way that is likely to harass, upset, embarrass, alarm or annoy any other
              person;</li>
            <li>not use the App in any way that advocates, promotes or assists any unlawful act such as (by way of
              example only) copyright infringement or computer misuse;</li>
            <li>not use the App in a way that could damage, disable, overburden, impair or compromise our systems or
              security or interfere with other users; and</li>
            <li>not collect or harvest any information or data from the App or our systems or attempt to decipher any
              transmissions to or from the servers running the App.</li>
          </ul>
          <p>You agree to indemnify UniSalad for any breach of the Acceptable Use Restrictions.</p>
          <p>UniSalad reserves the right to suspend or terminate access to the App to any user who breaches the
            Acceptable Use Restrictions.</p>
          <h2 className="mume-header" id="9-intellectual-property-rights">9. Intellectual Property Rights</h2>

          <p>All intellectual property rights in the App throughout the world belong to us and the rights in the App are licensed (not sold) to you. You have no intellectual property rights in, or to, the App other than the right to use it in accordance with these terms.</p>
          <h2 className="mume-header" id="10-liability">10. Liability</h2>

          <p>Although UniSalad takes as many measures as reasonably possible to ensure a safe and efficiently
            functioning App, it cannot guarantee the accuracy of the App service or any content therein.</p>
          <p>UniSalad is not liable for any action or inaction of users of the App, or for content provided by users
            of the App including without limitation:</p>
          <ul>
            <li>the descriptions or photos of tickets or items, including their accuracy and completeness;</li>
            <li>the quality, legality or safety of the tickets or items;</li>
            <li>the seller&apos;s, exchanger&apos;s or giver&apos;s title to the tickets or items; or</li>
            <li>any seller&apos;s or buyer&apos;s right to enter into a sale or the rights of the parties to an
              exchange or give away to enter into such transaction outside of the App.</li>
          </ul>
          <p>UniSalad is not a party to agreements entered between users of the App. No contracts exist between
            UniSalad and any students in any sale or exchanges of items.</p>
          <p>We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This
            includes liability for death or personal injury caused by our negligence or the negligence of our
            employees, agents or subcontractors or for fraud or fraudulent misrepresentation.</p>
          <h4 className="mume-header" id="when-we-are-liable-for-damage-to-your-property">When we are liable for damage
            to your property</h4>

          <p>If defective digital content that we have supplied damages a device or digital content belonging to you,
            we will either repair the damage or pay you compensation. However, we will not be liable for damage that
            you could have avoided by following our advice to apply an update offered to you free of charge or for
            damage that was caused by you failing to correctly follow installation instructions or to have in place
            the minimum system requirements advised by us.</p>
          <h4 className="mume-header" id="we-are-not-liable-for-business-losses">We are not liable for business losses</h4>

          <p>The App is for domestic and private use. If you use the App for any commercial or business purpose we
            will have no liability to you for any loss of profit, loss of business, business interruption, or loss of
            business opportunity.</p>
          <h4 className="mume-header" id="limitations-to-the-app">Limitations to the App</h4>

          <p>The App is provided for general information and entertainment purposes only. It does not offer advice on
            which you should rely. You should obtain professional or specialist advice before taking, or refraining
            from, any action on the basis of information obtained from the App.</p>
          <h2 className="mume-header" id="11-categories-in-the-app">11. Categories in the App</h2>

          <p>The App comprises five different categories (UniChat, Confessions, Buy & Sell, Housing, Lost & Found), which registered users can access for their own personal needs in accordance with these Terms of Service. In each of these categories, UniSalad reserves the right, at its sole discretion and without notice, to not post or to remove, at any time, any advertisement that does not comply with these Terms of Service or that it considers as damaging to its image or that of the App.</p>
          <p>The categories:</p>
          <h3 className="mume-header" id="111-uni-chat">11.1. UniChat</h3>

          <p>'UniChat' category is designed to offer a place where users can write content for other users to view and respond to. Users can post content about events happening in and around their university and share personal views, experiences and reviews.</p>
          <p>You must assume full responsibility for posts that are created by you or your device, including without limitation all content published about any third party. You must also ensure that all content published is accurate and true. UniSalad takes no responsibility for any content published.</p>
          <h3 className="mume-header" id="112-buy-sell">11.2. Buy &amp; Sell</h3>

          <p>The "Buy & Sell" category is designed for users to buy and sell tickets or items that they no longer need from or to other students within their university. It is the responsibility of the buyer and seller, and not of UniSalad, to arrange payment and delivery of the tickets or items outside of the App. No contracts are made between UniSalad and the buyers and sellers.</p>
          <h4 className="mume-header" id="sellers">Sellers</h4>

          <p>In respect of all items you offer for sale, exchange or give away through the &quot;Buy &amp; Sell&quot;
            category of the App:</p>
          <ul>
            <li>The sale of any animals, weapons, drugs or any illegal substance is strictly forbidden on the App.</li>
            <li>You must be the legal and beneficial owner of any tickets or items you offer to sell, exchange or
              give away and have the right to sell them, exchange them or give them away;</li>
            <li>The sale, exchange, give-away, use or possession of such tickets or items must not infringe the
              rights of any third party (including but not limited to any IP rights held by any third parties); and
              must not breach any applicable local, national or international law or regulation;</li>
            <li>By listing a ticket or item you represent and confirm that any description you provide is accurate
              and true.</li>
            <li>You may remove a ticket or item from the &quot;Buy/Sell&quot; category at any time before you have
              entered into an agreement to sell, exchange or give away the relevant ticket or item with a buyer in
              the App.</li>
          </ul>
          <h4 className="mume-header" id="ticket-specific-obligations">Ticket specific obligations</h4>

          <p>In the &quot;Buy &amp; Sell&quot; category, users can buy, sell and swap tickets between each other. The
            tickets are not offered or made available by UniSalad.</p>
          <ul>
            <li>Ticket sales on the App must be made at face value or below face value, unless the club/event has
              specified otherwise.</li>
            <li>If a certain club/event has expressly permitted ticket holders to offer their tickets for resale at a
              price higher than the face value of the ticket, such tickets may be offered for resale on the App for
              any amount up to the maximum amount specified by the club/event (if any).</li>
            <li>If a club/event has expressly permitted ticket holders to offer their tickets for resale at any
              price, such tickets may be offered for resale on the App at the price chosen by the selling user,
              provided always that the resale shall never be higher than 1.5 times the original face value of the
              ticket.</li>
            <li>It is the seller&apos;s responsibility to provide other users and UniSalad (should UniSalad request
              it, at any time) with evidence of permission from the club/event to resell the ticket and the permitted
              price.</li>
            <li>If any user resells or attempts to resell a ticket at a price above face value when not permitted to
              do so, UniSalad may suspend or terminate such user&apos;s App account without notice.</li>
          </ul>
          <h3 className="mume-header" id="113-lost-found">11.3. Lost &amp; Found</h3>

          <p>The &quot;Lost and Found&quot; category is designed for users to post information about items that they
            have found, and for users to look for information about items they may have lost.</p>
          <ul>
            <li>When posting about a found item, users must <strong>NOT</strong> reveal ANY personal data and private
              information relating to such item. By way of example, you must redact or &quot;black out&quot; all
              personal data or private information from any photograph of the item you may post. Personal data and
              private information includes but is not limited to names of individuals, addresses of such individuals,
              bank card numbers, phone numbers, national insurance numbers, codes, passwords and any other private or
              sensitive information.</li>
            <li>If you find an item, and post about it in the &quot;Lost and Found&quot; category of the App, you
              should also notify the local police of the items you have found.</li>
            <li>If a user claims that an item posted in the &quot;Lost and Found&quot; category belongs to him or
              her, it is the duty of the finder of the item to ensure the item is being returned to the correct
              person.</li>
            <li>The App only provides a platform for users to be reunited with their lost items and will we will not
              be liable for any items given to the wrong person, or damaged, or broken items returned.</li>
            <li>UniSalad cannot guarantee that users will find their lost item on the App.</li>
          </ul>
          <h3 className="mume-header" id="114-housing">11.4. Housing</h3>

          <p>The &quot;Housing&quot; category is designed for users to either advertise a spare room/rooms in their
            student house, or to look for advertised spare rooms in a student house.</p>
          <p>You are responsible for the content of any advertisements you post to the App, including any errors,
            omissions or inaccuracies contained in such advertisements. We accept no liability to you or any other
            person for the content of any advertisements posted on our App. We advise that you check the content of
            your advertisement immediately after posting it to the App to ensure you are happy with it.</p>
          <p>If you post an advertisement for a room, you must ensure that:</p>
          <ul>
            <li>all advertisements posted to the App are for student houses ONLY, located in the city/town/area of
              the university specific to the App to which you are registered;</li>
            <li>all advertisements posted to the App are for residential lets only and are compliant with all
              applicable legislation;</li>
            <li>all the information you include in your advertisement is truthful and, to the best of your knowledge
              and belief, accurate and not in any way misleading;</li>
            <li>any photos or videos you include in your advertisement are current and accurate representations of
              the room(s) you are advertising;</li>
            <li>you have all necessary rights and permissions to advertise and/or rent or let the applicable room or
              property by your landlord (and we reserve the right to require evidence of such rights and/or
              permissions);</li>
            <li>you do not post multiple advertisements for the same room and/or property;</li>
            <li>you do not post dummy or fake advertisements for rooms and/or properties that are not available for
              let or are not available to be viewed; and</li>
            <li>all viewing arrangements, meetings and contract signings are undertaken with the consent and presence
              of the house owner and/or landlord.</li>
          </ul>
          <h3 className="mume-header" id="115-travel">11.5. Travel</h3>

          <p>The &quot;Travel&quot; category is designed for qualifying students to carpool, or to resell tickets of
            travel.</p>
          <p>For carpool, drivers travelling to a given destination on a specific date can offer their carpooling
            services on the App. Passengers wishing to travel to a specific destination on a given date can post an
            advertisement on the group. Drivers can request a contribution to the cost of fuel on their
            advertisements for the given journey.</p>
          <p>The exchange of payment for a trip is to be arranged between driver and passenger outside of the App.</p>
          <p>Drivers can advertise their carpool service only if they meet the criteria set out below.</p>
          <p>Drivers can post about their carpool service by entering the destination they are driving to, the time
            and place from which they will depart, the cost of fuel for the trip and any further details they may
            wish to add.</p>
          <p>Passengers interested in such a trip are able to send a message to the driver through the UniSalad
            messaging service. Arrangements and payment for the trip are to be completed outside of the UniSalad App.</p>
          <p>The identity of driver and the passenger(s) must correspond to the identity communicated to the App and
            to the other members participating in the trip. You are not permitted to post an advertisement for any
            other driver. You are permitted to book one or more seats as a passenger on behalf of a third party,
            provided that the third party is a student who qualifies to set up an account on the App. In such case,
            the user booking the seat must inform the driver, at the time of the booking, of the name, age and
            qualifying email address of the person on whose behalf the user is booking a seat. It is strictly
            prohibited to book a seat for anyone under the age of 18.</p>
          <p>The &quot;Travel&quot; category is intended for the booking of seats for individuals. You are not
            permitted to book a seat to transport any object, package, animal traveling alone or other material
            items. In the event that a passenger has a large item to carry with them, the passenger must inform the
            driver at the time of the booking request.</p>
          <p>You agree to use the App only to be put into contact, on a non-business and non-commercial basis, with
            people wishing to share a trip with you.</p>
          <p>If you are the driver, it is your responsibility to calculate the amount you will charge for your
            carpool service. This calculation must be based on the miles covered, the estimated MPG (miles per
            gallon) of your vehicle and the price of fuel. The total amount can then be divided between the number of
            passengers who will join you on your journey. All parties to a trip must agree to any requested payments
            and payment from the passenger(s) to the driver should be made in advance of the trip.</p>
          <p>In case of trip cancellation for any given reason, the driver must inform the passenger(s) as soon as
            possible, and promptly refund them any payments already made.</p>
          <p>If you are the driver, you agree not to request a cost contribution higher than the costs you actually
            incur and which may generate a profit. In the spirit of cost sharing, you have to bear as a driver your
            own part of the costs incurred as a result of the trip.</p>
          <p>UniSalad reserves the right to suspend or terminate your account if you use a chauffeur-driven or other
            business vehicle or taxi, or a company car, or if you generate a profit from the App. You agree to
            provide to UniSalad, on request, a copy of your car registration certificate and/or any other document
            showing that you are authorised to use this vehicle on the App and are not drawing any profit therefrom.</p>
          <p>The driver must remove their advertisement from the &quot;Travel&quot; category immediately before they
            are due to depart on that trip.</p>
          <h4 className="mume-header" id="drivers">Drivers</h4>

          <p>You may advertise a carpool only if you fulfil all the following conditions:</p>
          <ul>
            <li>you hold a valid driving licence and are legally entitled to drive in each jurisdiction through which
              you will be traveling;</li>
            <li>you have no contraindication or medical incapacity for driving;</li>
            <li>you only advertise for trips where you will be driving a vehicle that you own or use with the express
              permission of the owner, and that you are authorised to use for the purposes of carpooling;</li>
            <li>you are and remain the main driver of the vehicle to be used on the trip;</li>
            <li>the vehicle has valid third party insurance;</li>
            <li>the vehicle to be used for the trip is a touring car with 4 wheels and a maximum of 7 seats;</li>
            <li>your insurance covers carpooling and your passengers are considered as third parties in your vehicle
              and are therefore covered by your insurance during the whole trip, even if cross-border;</li>
            <li>you do not post another advertisement for the same trip on the App;</li>
            <li>you do not offer more seats than the number available in your vehicle;</li>
            <li>all seats in the vehicle have a seatbelt, even if the vehicle is approved to have seats with no
              seatbelt;</li>
            <li>you use a vehicle in good working order and which complies with the applicable laws and regulations,
              including without limitation an up-to-date MoT certificate and paid vehicle excise duty; and</li>
            <li>you do not contact any other user, via the App or otherwise, for any purpose other than agreeing the
              terms of the carpooling.</li>
          </ul>
          <p>Furthermore, at all times when using the App and during trips, you undertake:</p>
          <ul>
            <li>not to use the App for professional, commercial or profit-making purposes;</li>
            <li>not to send UniSalad or any other users any false, misleading, malicious or fraudulent information;</li>
            <li>not to open more than one account on the App and not to open an account in the name of a third party;</li>
            <li>not to accept or make payment outside of that agreed as a cost contribution, save in the cases
              authorised by these Terms of Service;</li>
            <li>in the case of delay or change to the time or the trip, to promptly inform your passengers without
              delay;</li>
            <li>in the case of a cross-border trip, to hold and keep available to the passenger(s) and any authority
              that may so request any document evidencing your identity and your right to cross the border;</li>
            <li>to wait for passengers at the agreed meeting place for at least 15 minutes after the agreed meeting
              time;</li>
            <li>to ensure you can be contacted by your passengers via the App;</li>
            <li>to behave appropriately and responsibly during the trip, and in compliance with the spirit of
              carpooling;</li>
            <li>not to decline any booking based on race, colour, ethnicity, national origin, religion, sexual
              orientation, marital status, disability, physical appearance, marital status, pregnancy, special
              vulnerability due to their economic situation, name, place of residence, health, political opinion, or
              age.</li>
          </ul>
          <p>You are solely responsible for the content of the advertisement you post on the &quot;Travel&quot;
            category. Consequently, you represent and warrant the accuracy and truthfulness of all information
            contained in your advertisement, and you undertake to fulfil the trip under the conditions described in
            your advertisement.</p>
          <h4 className="mume-header" id="passengers">Passengers</h4>

          <p>When you use the carpool as a passenger, you undertake:</p>
          <ul>
            <li>to adopt appropriate behaviour during the trip so as not to hinder the concentration or driving of
              the driver or the peace and quiet of the other passengers;</li>
            <li>to respect the driver&apos;s vehicle and its cleanliness;</li>
            <li>in the case of delay in arriving at the meeting place, to inform the driver without delay;</li>
            <li>to pay the driver the agreed cost contribution before the start of the trip;</li>
            <li>to wait for the driver at the meeting place for at least 15 minutes beyond the agreed time;</li>
            <li>to provide to UniSalad, or any driver who so asks, your identity card or any document evidencing your
              identity;</li>
            <li>not to carry during a trip any item, goods, substance or animal that could hinder the driving and
              concentration of the driver, or the nature, possession or carriage of which is contrary to any legal
              provisions in force; and</li>
            <li>in the case of a cross-border trip, to hold and keep available to the driver and any authority that
              may so request any document evidencing your identity and your right to cross the border.</li>
          </ul>
          <h4 className="mume-header" id="reselling-travel-tickets">Reselling travel tickets</h4>

          <p>&quot;Confessions&quot; category is designed to offer a place where users can write content anonymously for other users to view and respond to. Users can post content about events happening in and around their university and share personal views, experiences and reviews.</p>
          <p>It is the user&apos;s responsibility to provide the correct information of the mode of transport, travel
            company, date, time and place of the departure and arrival time of a ticket. Users must not make any
            profit on reselling a travel ticket.</p>

          <h3 className="mume-header" id="115-travel">11.6. Confessions</h3>

          <p>The &quot;Travel&quot; category is designed for qualifying students to carpool, or to resell tickets of
            travel.</p>
          
          <p>You must assume full responsibility for posts that are created by you or your device, including without limitation all content published about any third party. You must also ensure that all content published is accurate and true. UniSalad takes no responsibility for any content published.</p>

          <h2 className="mume-header" id="12-reporting-misconduct">12. Reporting misconduct</h2>

          <p>If at any point during your time on the App, you feel another user has acted in an inappropriate way
            towards you, including but not limited to offensive, violent or sexually inappropriate behaviour, you
            should immediately make a report to the appropriate authorities and then report them to UniSalad. You are
            able to do this either by reporting an individual&apos;s post using the reporting features within the
            App, or by sending us a message in the Contact Us section within the App.</p>
          <h2 className="mume-header" id="13-promotions">13. Promotions</h2>

          <p>From time to time we may post promotions, competitions, games or prize draws on the App and special
            terms and conditions related to the same.</p>
          <p>If there is any conflict between such special terms and conditions and these Terms of Service, the
            special terms and conditions shall prevail.</p>
          <h2 className="mume-header" id="14-modifications-of-the-terms-of-service">14. Modifications of the Terms of
            Service</h2>

          <p>UniSalad may change these Terms of Service from time to time. If we do so, we will post the updated
            terms within the App. If you are registered on the App, we will send you an email to let you know about
            the changes of the terms. If these future changes are unacceptable to you, you must immediately stop
            using the App.</p>
          <p>Continuing to use the App following any revisions to these Terms and Conditions constitutes your
            acceptance of any and all such changes. UniSalad may change, modify, suspend or discontinue any element
            of the App at any time without notice or liability. UniSalad may also impose limits on the certain
            features or restrict access to parts or all of the App, without notice or liability.</p>
          <h2 className="mume-header" id="15-applicable-law-disputes">15. Applicable Law - Disputes</h2>

          <p>These Terms of Service are governed by English law and you can bring legal proceedings in respect of the
            App in the English courts. If you live in Scotland you can bring legal proceedings in respect of the App
            in either the Scottish or the English courts. If you live in Northern Ireland you can bring legal
            proceedings in respect of the App in either the Northern Irish or the English courts.</p>
          <h2 className="mume-header" id="16-contact-us">16. Contact us</h2>

          <p>If you have any questions about these Terms of Service or about UniSalad, please contact us by writing
            to <a href="mailto:support@unisalad.co.uk" style={{color: 'blue', textDecoration: 'underline'}}>support@unisalad.co.uk</a>.</p>
        </article>
      </Page>
    );
  }
}
