import React from 'react';

import BlogPost from './blog-post';

export default class Blog4 extends React.Component {

  render() {
    return (
      <BlogPost>
        <h1 style={{ marginBottom: '16px' }}>Interview with your SU Officers candidates</h1>

        <h3 style={{ marginBottom: '24px' }}>
          We caught up with some of the candidates running for SU Officer positions this week, giving insights into
          their campaigns, thoughts on life at uni next term, and advice under lockdown!
        </h3>

        <p>
          <em>(All responses are anonymous not all candidates took part)</em>
        </p>

        <h5 className="mb-4">1) What made you want to run for this position?</h5>

        <p>
          <strong>Candidate 1:</strong> My friends. I wouldn't be as invested or interested in this area if it wasn't
          for chatting to my friends and learning about social justice issues.
        </p>

        <p>
          <strong>Candidate 2:</strong> From a young age, the basis behind the position I'm running for has always been
          an important aspect of my life leading to a clear passion for it. I'd love nothing more than the chance to
          give the same opportunity back to other students because personally, I am beyond grateful for all the
          opportunities that it has offered me.
        </p>

        <p>
          <strong>Candidate 3:</strong> My policies are based on personal experiences as well as my experience working
          in the SU for two years. I've heard all about the issues students face across all campuses and I want to make
          a change!
        </p>

        <p>
          <strong>Candidate 4:</strong> Covid-19 has torn the Community apart and it needs serious bringing together.
          And I genuinely believe I am the candidate for that. I feel a great sense of attachment to the Students of
          Nottingham and have spoken to as many as I can to get policy inspiration and approval. That's why I want to
          connect Students, balance our Campuses, protect individuals and fight for a better quality of Community.
        </p>

        <p>
          <strong>Candidate 5:</strong> I always believed this officer position should be someone that already works for
          the community, and not someone looking for job after university. Since I had been involved in as many
          community activities as I could, I really feel like I can benefit the community with this experience.
        </p>

        <h5 className="mb-4">
          2) How have you managed the planning for this week under lockdown? Has it been a smooth process?
        </h5>

        <p>
          <strong>Candidate 1:</strong> I've been planning it for a couple months, so it's been a smooth(ish) process
          because little bits have been done here and there along the way. I think the main issue we've all had is
          getting people to engage with the campaigns under the circumstances we're in!
        </p>

        <p>
          <strong>Candidate 2:</strong> I do everything ridiculously early, so I had a plan in place a while go -
          however I have barely followed it. It was changed basically as soon as the week started - so definitely wasn't
          a smooth process.
        </p>

        <p>
          <strong>Candidate 3:</strong> It's been really smooth because I have a fantastic campaigns team behind me!
        </p>

        <p>
          <strong>Candidate 4:</strong>  Election season is never smooth and to say it was completely smooth would be a
          lie. But by having a solid, triple-checked plan for the week and a large campaign team behind me, we have been
          able to rise the waves when they have come. Yes, there has been the usual political points scoring, but that
          can be expected.
        </p>

        <p>
          <strong>Candidate 5:</strong> I have been focusing on my coursework for the most part, the campaigning came
          second. Due to social distancing I was unable to film the videos I was planning and instead had organise
          social media posts.
        </p>

        <h5 className="mb-4">3) How has the support for your campaign gone so far?</h5>

        <p>
          <strong>Candidate 1:</strong> Really positive so far. Our campaign video has been viewed a lot. I think the
          most overwhelming thing has been the support from friends and people you know (even vaguely!). Never did I
          expect this many people to share my branding and that's what has made it a really amazing experience so far.
        </p>

        <p>
          <strong>Candidate 2:</strong> I think it has one well! I'm a bit of pessimist but I've got my fingers firmly
          crossed!
        </p>

        <p>
          <strong>Candidate 3:</strong> I've had an overwhelmingly positive response and I hope this is reflected in the
          results! I'm so happy there are so many engaged students at UON!
        </p>

        <p>
          <strong>Candidate 4:</strong> It has been overwhelming! I could never have imagined the fantastic response
          from University of Nottingham Students! There has been a wonderful reaction to our social media and to every
          <em>virtual</em> interaction I have with Student. People have been incredibly supportive of our many diverse
          policies, and I think that can only bode well.
        </p>

        <p>
          <strong>Candidate 5:</strong> Supports been amazing, had so many people reach out to me to wish me luck,
          including some MPs. People really love my manifesto points!
        </p>

        <h5 className="mb-4">4) What will be your first point of call, should you win this week?</h5>

        <p>
          <strong>Candidate 1:</strong> My bed lol. After that, probably thanking everyone whose been a part of the
          incredible journey, finishing my studies and then taking a break before starting the role in July!
        </p>

        <p>
          <strong>Candidate 2:</strong> Having a beer and zoom calling my friends probably crying knowing me! Then the
          next day, I would realise I have 3 essays and a dissertation to finish and probably get really stressed about
          it!
        </p>

        <p>
          <strong>Candidate 3:</strong> Definitely making sure that the handover process runs smoothly and sending thank
          you out to everyone that helped in my campaign!
        </p>

        <p>
          <strong>Candidate 4:</strong> Well since there are no pubs open at the moment, I think I shall have a cup of
          tea with family and Zoom-call my campaign team!
        </p>

        <p>
          But in reality, if I were fortunate to receive the backing of UoN's Student body then planning and
          preparations for helping ease our Community out of this pandemic would have already begun.
        </p>

        <p>
          <strong>Candidate 5:</strong> Setting up an SU approved list of estate agents whilst a proper SU lettings
          agency is set up, I want to combat rogue landlords from day 1!
        </p>

        <h5 className="mb-4">
          5) Do you think university life will be different next academic year due to Covid-19? If so, how, and how do
          you think your position will be affected?
        </h5>

        <p>
          <strong>Candidate 1:</strong> Massively. I think the effects of COVID-19 will disseminate into so many more
          avenues than we expect, and I don't think we can really tell how it'll affect the positions until we actually
          get there. Right now, I think the most difficult thing at the start will be trying to make those positive
          working relationships that you'd normally build, with your officer team, with SU staff, key University
          stakeholders. I think people will be reluctant to start new big projects soon after this, but that's where us
          as Officer should really try to push that student voice, especially with the introduction of the new roles.
        </p>

        <p>
          <strong>Candidate 2:</strong> I really do. I think the COVID19 is a turning point globally. During my time in
          quarantine, I think I've definitely become more appreciative of the smaller things in life like seeing
          friends, family and just generally being able to play sport. It has reminded how fortunate I am to have these
          opportunities in my life. I really hope this is shared feeling throughout everyone and is reflected through
          the way everyone lives day to day as well.
        </p>

        <p>
          At university, I expect the general cleanliness will be improved across the board everywhere. The pandemic has
          also caused a lot of stress and had some real economic strain on some people. I hope that people are there for
          each other even more so than they were before. Financially the whole world has taken a huge hit and will take
          some time to recover, this will roll into every aspect of society especially universities that have been
          closed for so long. Considering this, I think as this officer position, I would have to be generally
          financially aware, thinking of new ways to save money and also spend it wisely. I would also have to think of
          new and innovative ways to provide welfare, ensuring any resources someone may need are readily available and
          just generally be there for anyone who needs a chat.
        </p>

        <p>
          <strong>Candidate 3:</strong> Covid-19 poses definite challenges to how the SU is run. I think it will
          definitely be a case of making sure students feel supported and connected regardless of whether we are on or
          off campus.
        </p>

        <p>
          <strong>Candidate 4:</strong> I think we will have to be a far more conscious community, which reflects many
          of the other policies I'm championing. This Officer role will need to see an expanded kind of interaction and
          relationship between the SU and Student body, with a lot more tailored interactions and wellbeing care.
          Students have told me they feel at times the SU is disconnected from them, so at the core of my candidacy is
          reconnection our Community.
        </p>

        <p>
          There will be a greater emphasis on holding the University to account on behalf of Students, ensuring measures
          like No Academic Disadvantage are extended to consider the complex needs of Student life beyond simply the
          academic year that has just ended. It is no fault of Students that we are in this pandemic, but at times we
          have felt forgotten.
        </p>

        <p>
          <strong>Candidate 5:</strong> I think there will be a lot less students, I have heard many people say they are
          considering deferring a year if it is online for the first semester like some unis. The role will definitely
          be a lot more challenging if it is all done online. A lot more time spent on zoom I would imagine.
        </p>

        <h5 className="mb-4">6) How have you personally coped with the lockdown?</h5>

        <p>
          <strong>Candidate 1:</strong> It's been a mixture. There's been loads of ups and downs but usually day-to-day
          life has been a constant state of "I'm ok", rather "I'm really good" or "I'm really bad". I'm still in
          Nottingham, and the only one of my uni house still here. This has meant that I've been able to focus on my
          dissertation a lot more than if my friends were here, but loneliness and under-stimulation are definitely
          starting to hit more recently.
        </p>

        <p>
          <strong>Candidate 2:</strong> I've been at home since the lockdown was first announced. My mum luckily is the
          head animal coordinator for the RSPCA in Milton Keynes and North Bucks, so we have had 11 puppies to look
          after during lock down which are the best break and stress relief. I am lucky that lockdown has been
          absolutely fine for me, but as expected I have missed my friends, normal life and found it difficult to do my
          university work. I also struggled with how I felt my final year of university had been taken from me. It's so
          hard to complete essays and dissertations when there's so many distractions around you and very little things
          that can motivate you.
        </p>

        <p>
          <strong>Candidate 3:</strong> I'm living back home so that I could be with my Mom. We're coping reasonably
          well but it's been really hard not seeing my friends and boyfriend. I've taken up embroidery as a new skill so
          I'm really enjoying that!
        </p>

        <p>
          <strong>Candidate 4:</strong> <strong>Exercise!</strong> I have been completing a fundraiser for NHS Charities
          Together to aid the Covid-19 appeal.
        </p>

        <p>
          <strong>Working</strong> - on University work and the campaign, but also for the many charities I represent
          and champion.
        </p>

        <p>
          <strong>Staying Connected to friends</strong> - to make sure no-one has suffered in silence during Covid-19.
        </p>

        <p>
          <strong>Candidate 5:</strong> I am living in Beeston at the moment and the community mutual aid is amazing,
          shout out Broxtowe Labour for providing many in the community food if they need it. The most difficult part of
          the lockdown is definitely the monotony of the days whilst trying to complete my degree.
        </p>

        <h5 className="mb-4">7) Any advice to other students for the rest of the lockdown?</h5>

        <p>
          <strong>Candidate 1:</strong> Try to keep yourself busy. This is my coping mechanism and it might not be the
          same for everyone, but I've learnt that time goes a lot faster if I'm doing things. Whether that be watching
          films, reading for my dissertation, or just scrolling Instagram. And if you feel like you need a break, do so!
          These aren't ordinary times and therefore the ordinary schedules won't work. Find something that works for you
          during these times and keep doing that Probably the most important thing is that if things aren't going well
          at the moment, there is a massive community at Nottingham who are there for you and the support is there if
          you need it.
        </p>

        <p>
          <strong>Candidate 2:</strong> Just stay strong, keep going and remember were over halfway now! I keep telling
          myself this is going to be a great story one day - one to tell the grandkids so remember that and think of how
          great everything will be when this is all over!
        </p>

        <p>
          <strong>Candidate 3:</strong> Try to find something to occupy yourself if you can - now is a great time to
          learn a new skill if you're able to! As I say embroidery has really helped me relax, but even colouring or
          doing something not on your phone is good! Also, if you're able to go outside for some fresh air that's
          definitely something I recommend.
        </p>

        <p>
          <strong>Candidate 4:</strong> Please <strong>put YOUR wellbeing</strong> first. This pandemic is not drill.
          In many cases it is a real matter of life or death. Many Students have lost loved ones, and the SU has lost
          members like Ruth Southall too. So please, spend this time with family and protecting your mental health. Do
          what you can to pass the time: pick up a new hobby? Try some cooking? Or spend the time reflecting positively?
          There are 24 hours in a day, and right now we don't know when this will end. Life will return to normal soon.
          Sacrificing things today, allows you to live for tomorrow. I promise that if elected I shall do everything in
          my role to help Connect, Rebuild and Carry our Community back to whatever "normality" looks like. And always
          putting Student's first!
        </p>

        <p>
          Stay Safe! Stay Home! Reach-out if you need.
        </p>

        <p>
          <strong>Candidate 5:</strong> Stay safe and support your community the best you can!
        </p>
      </BlogPost>
    );
  }
}
