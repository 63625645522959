import React from 'react';
import { Link } from 'react-router-dom';

import { applyParallax } from '../parallax';

import logo from '../assets/img/logo/ss-logo-138x33.png';
import logo2x from '../assets/img/logo/ss-logo-138x33.png';

import bannerPhoto from '../assets/img/graphics/photo-1429962714451-bb934ecdc4ec (unsplashcom_photos_hzgs56Ze49s).jpg';

import appStoreBadge from '../assets/img/badges/app-store-badge.svg';
import googlePlayBadge from '../assets/img/badges/google-play-badge.svg';

import appHome307x6361x from '../assets/img/graphics/app-home-1.png';
import appHome307x6362x from '../assets/img/graphics/app-home-1.png';
import appHome230x4771x from '../assets/img/graphics/app-home-1.png';
import appHome230x4772x from '../assets/img/graphics/app-home-1.png';
import appQa299x6191x from '../assets/img/graphics/app-qa-1.png';
import appQa299x6192x from '../assets/img/graphics/app-qa-1.png';

import buySection from '../assets/img/graphics/buy-section.png';
import houseSection from '../assets/img/graphics/house-section.png';
import lostSection from '../assets/img/graphics/lost-section.png';
import confessionsSection from '../assets/img/graphics/confessions-section.png';
import wallSection from '../assets/img/graphics/wall-section.png';

import handshake from '../assets/img/graphics/handshake.svg';
import notification from '../assets/img/graphics/notification.svg';
import signIn from '../assets/img/graphics/sign-in.svg';
import student from '../assets/img/graphics/student.svg';
import sustainability from '../assets/img/graphics/sustainability.svg';

import impactMagazineBrand from '../assets/img/brands/impact-magazine-brand.png';
import nottinghamPostBrand from '../assets/img/brands/nottingham-post-brand.png';
import quenchBrand from '../assets/img/brands/quench-brand.png';
import theTabBrand from '../assets/img/brands/the-tab-brand.png';
import universityRadioNottinghamBrand from '../assets/img/brands/university-radio-nottingham-brand.png';

export default class AppScroller extends React.Component {

  componentDidMount() {
    applyParallax('#download', bannerPhoto);
  }

  render() {
    return [
      <nav key="navbar" id="navbar" className="navbar navbar-expand-lg navbar-light fixed-top">
        <div className="container">
          <Link className="navbar-brand mx-0 mx-lg-3" to="/">
            <img className="align-middle mr-2"
              src={logo}
              srcSet={`${logo} 1x, ${logo2x} 2x`}
              width="138" height="33" alt="" />
          </Link>

          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerContent" aria-controls="navbarTogglerContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse mx-0 mx-lg-3" id="navbarTogglerContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item mx-2">
                <Link className="nav-link" to="/">App</Link>
              </li>

              <li className="nav-item mx-2">
                <Link className="nav-link" to="/blog">Blog</Link>
              </li>

              <li className="nav-item mx-2">
                <Link className="nav-link" to="/about">About Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      ,
      <header key="download" id="download" className="us-banner">
        <div className="w-100 us-dimmer">
          <div className="container d-flex us-banner-content justify-content-center">
            <div className="d-none d-lg-block order-lg-1 order-2" style={{ padding: '0 40px 0 40px' }}>
              <img className="us-phone-img"
                alt=""
                src={appHome307x6361x}
                srcSet={`${appHome307x6361x}, ${appHome307x6362x} 2x`}
                width="307" height="636" />
            </div>

            <div className="us-info order-lg-2 order-1 d-flex flex-column align-items-center">
              <div className="us-slogan mb-2">Your student community and marketplace app</div>

              <div className="us-blurb mb-3 mb-sm-4 mb-lg-5">Connect. Buy. Sell. Find. Share.</div>

              <div className="text-center d-flex flex-column d-sm-block" style={{ marginBottom: '10px' }}>
                <span className="px-2">
                  <a href="https://itunes.apple.com/gb/app/unisalad/id1448389031?mt=8" target="_blank" rel="noopener noreferrer">
                    <img alt="Get it on the App Store" src={appStoreBadge} width="204" height="72" />
                  </a>
                </span>

                <span className="px-2">
                  <a href="https://play.google.com/store/apps/details?id=com.unisalad.android" target="_blank" rel="noopener noreferrer">
                    <img alt="Get it on Google Play" src={googlePlayBadge} width="204" height="72" />
                  </a>
                </span>
              </div>
            </div>
          </div>

          <div className="mb-4 container d-flex d-lg-none justify-content-center">
            <img className="us-phone-img"
              alt=""
              src={appHome230x4771x}
              srcSet={`${appHome230x4771x}, ${appHome230x4772x} 2x`}
              width="230" height="477" />
          </div>
        </div>
      </header>
      ,
      <section key="join" id="join" className="us-section us-alternate" style={{ paddingTop: '160px' }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-6 my-4 my-lg-0 us-pl-lg-6">
              <h1 className="us-section-header text-center text-lg-left mb-3" style={{ color: 'rgb(49, 54, 56)' }}>Connect with your student community</h1>
              <p className="text-center text-lg-left" style={{ color: 'rgb(142, 142, 147)' }}>
                UniSalad is a student-only platform where you can connect with other students
                from the same university, ask questions, share advice and solve your everyday
                needs. The app is made up of 5 categories: Q &amp; A, Buy &amp; Sell, Lost &amp; Found, Housing
                and Confessions, for you to easily filter through to find exactly what you need whilst at
                university.
              </p>
              <p className="text-center text-lg-left" style={{ color: 'rgb(142, 142, 147)' }}>
                Student news, advice, tickets, books, lost phones, car lifts, spare rooms and more.
                Find something you're looking for, get rid of something you no longer need, and
                catch up on the latest uni chat with the UniSalad app.
              </p>
            </div>

            <div className="col-12 col-lg-6 text-center us-pr-lg-6">
              <img className="img-fluid" alt="Sign in with Facebook or your profile" src={signIn} width="400" height="400" />
            </div>
          </div>
        </div>
      </section>
      ,
      <section key="features" id="features" className="us-section us-dark-colored">
        <div className="container">
          <h1 className="us-section-header text-center mb-5">Inside the UniSalad App</h1>

          <div className="row">
            <div className="col-12 col-lg-4">
              <div className="us-description text-center text-lg-right us-pl-lg-6">
                <img className="us-description-icon" alt="" src={buySection} width="30" height="30" />
                <h3 className="text-center text-lg-right us-description-header">Buy &amp; Sell</h3>
                <p className="text-center text-lg-right">
                Buy and sell tickets, course books, bikes and everything in between for free, directly with other
                students. Search directly for event or item name, and get notified when something you want
                becomes available.
                </p>
              </div>

              <div className="us-description text-center text-lg-right us-pl-lg-6">
                <img className="us-description-icon" alt="" src={lostSection} width="30" height="30" />
                <h3 className="text-center text-lg-right us-description-header">Lost &amp; Found</h3>
                <p className="text-center text-lg-right">
                  Lost something on a night? Found something around campus? Flag it to your student community.
                </p>
              </div>
            </div>

            <div className="col-12 col-lg-4 d-none d-lg-inline text-center">
              <img
                alt=""
                src={appQa299x6191x}
                srcSet={`${appQa299x6191x}, ${appQa299x6192x} 2x`}
                width="299" height="619" />
            </div>

            <div className="col-12 col-lg-4">
              <div className="us-description text-center text-lg-left us-pr-lg-6">
                <img className="us-description-icon" alt="" src={houseSection} width="30" height="30" />
                <h3 className="text-center text-lg-left us-description-header">Housing</h3>
                <p className="text-center text-lg-left">
                  Left finding a room to the last minute and face sleeping in the library? Housemate dropped out?
                  Advertise and look for spare rooms directly with students from your university.
                </p>
              </div>

              <div className="us-description text-center text-lg-left us-pr-lg-6">
                <img className="us-description-icon" alt="" src={confessionsSection} width="30" height="30" />
                <h3 className="text-center text-lg-left us-description-header">CONFESSIONS</h3>
                <p className="text-center text-lg-left">
                  Dive into the app and share your confessions or react to the latest buzz. It's all anonymous, so you can say what you really think!
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 offset-0 col-lg-4 offset-lg-4">
              <div className="us-description us-description-bottom text-center">
                <img className="us-description-icon" alt="" src={wallSection} width="30" height="30" />
                <h3 className="text-center us-description-header">Q &amp; A</h3>
                <p className="text-center">
                  Post questions, advice or share helpful student life tips with others, and stay up to
                  date with your student community. You can choose to engage semi-anonymously to
                  share your true opinions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      ,
      <section key="notified" className="us-section us-section-sm-padding us-alternate">
        <div className="container">
          <div className="row align-items-center my-0 my-lg-5">
            <div className="col-12 col-lg-6 my-4 my-lg-0 text-center text-lg-right" style={{ padding: '0 90px 0 90px' }}>
              <img alt="" src={student} width="168" height="192" className="img-fluid" />
            </div>

            <div className="col-12 col-lg-6 my-4 my-lg-0 us-pl-lg-4-5 us-pr-lg-7">
              <h1 style={{ color: 'rgb(49, 54, 56)' }} className="text-center text-lg-left mb-3">Student-only community</h1>

              <p className="text-center text-lg-left" style={{ color: 'rgb(142, 142, 147)' }}>
                Been scammed in the past? Not sure what social
                platform you can really trust?
              </p>
              <p className="text-center text-lg-left" style={{ color: 'rgb(142, 142, 147)' }}>
                Only users with a valid student email address from
                your university can interact with you on the app,
                which makes for a more secure environment.
              </p>
            </div>
          </div>

          <div className="row align-items-center my-0 my-lg-5">
            <div className="col-12 col-lg-6 order-lg-1 order-2 my-4 my-lg-0 us-pr-lg-4-5 us-pl-lg-7">
              <h1 style={{ color: 'rgb(49, 54, 56)' }} className="text-center text-lg-right mb-3">Get notified!</h1>

              <p className="text-center text-lg-right" style={{ color: 'rgb(142, 142, 147)' }}>
                Sign up for alerts and get notified as soon as another student has posted something you requested. This means
                no time wasted searching the app every 2 minutes.
              </p>
            </div>

            <div className="col-12 col-lg-6 order-lg-2 order-1 my-4 my-lg-0 text-center text-lg-left" style={{ padding: '0 90px 0 90px' }}>
              <img alt="" src={notification} width="168" height="192" />
            </div>
          </div>

          <div className="row align-items-center my-0 my-lg-5">
            <div className="col-12 col-lg-6 my-4 my-lg-0 text-center text-lg-right" style={{ padding: '0 90px 0 90px' }}>
              <img alt="" src={handshake} width="240" height="192" className="img-fluid" />
            </div>

            <div className="col-12 col-lg-6 my-4 my-lg-0 us-pl-lg-4-5 us-pr-lg-7">
              <h1 style={{ color: 'rgb(49, 54, 56)' }} className="text-center text-lg-left mb-3">A fair price</h1>

              <p className="text-center text-lg-left" style={{ color: 'rgb(142, 142, 147)' }}>
                We know you need to look after every penny at university.
                So we're working hard to ensure students are only selling items at reasonable prices.
              </p>
            </div>
          </div>

          <div className="row align-items-center my-0 my-lg-5">
            <div className="col-12 col-lg-6 order-lg-1 order-2 my-4 my-lg-0 us-pr-lg-4-5 us-pl-lg-7">
              <h1 style={{ color: 'rgb(49, 54, 56)' }} className="text-center text-lg-right mb-3">Pushing for a greener future</h1>

              <p className="text-center text-lg-right" style={{ color: 'rgb(142, 142, 147)' }}>
                UniSalad encourages students to buy and sell unwanted items to increase re-use and reduce waste, lift share to
                split carbon emission, and help others find lost belongings to avoid them buying new items again.
              </p>
            </div>

            <div className="col-12 col-lg-6 order-lg-2 order-1 my-4 my-lg-0 text-center text-lg-left" style={{ padding: '0 90px 0 90px' }}>
              <img alt="" src={sustainability} width="175" height="192" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>
      ,
      <section key="reviews" id="reviews" className="us-section us-colored">
        <div className="container">
          <div className="row">
            <div className="px-4 my-3 my-lg-0 col-12 col-lg-4 text-center">
              <blockquote className="blockquote">
                <p>Everything essential to student life is on this app! Haven't got tickets for an event? First point of call: UniSalad</p>
                <footer className="blockquote-footer">Ella <cite>University of Nottingham, 1st year</cite></footer>
              </blockquote>
              <span className="review-rating">
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
              </span>
            </div>

            <div className="px-4 my-3 my-lg-0 col-12 col-lg-4 text-center">
              <blockquote className="blockquote">
                <p>All you need as a student in one place. Best thing you'll do is get UniSalad! Don't miss out!</p>
                <footer className="blockquote-footer">Hannah <cite>Nottingham Trent University, 2nd year</cite></footer>
              </blockquote>
              <span className="review-rating">
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
              </span>
            </div>

            <div className="px-4 my-3 my-lg-0 col-12 col-lg-4 text-center">
              <blockquote className="blockquote">
                <p>With only students from your uni on the app, you know it's safe. I found a lift to Belgium on UniSalad, and I wouldn't have trusted any other social media platform for this.</p>
                <footer className="blockquote-footer">Julien <cite>Cardiff University, 4th year</cite></footer>
              </blockquote>
              <span className="review-rating">
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
              </span>
            </div>
          </div>
        </div>
      </section>
      ,
      <section key="media" id="media" className="us-section us-alternate">
        <div className="container">
          <h1 className="us-section-header text-center" style={{ marginBottom: '60px' }}>We've been featured!</h1>

          <div className="row">
            <div className="col-12 col-lg-4 mb-5 mb-lg-0 d-flex justify-content-center">
              <div className="text-center" style={{ maxWidth: '240px' }}>
                <a href="https://thetab.com/uk/nottingham/2018/11/12/founder-of-buy-sell-facebook-group-set-to-launch-buy-sell-app-40688?fbclid=IwAR18nThSemu7K5FYgPUUjoeQ4bXFyxk69WQCOEl-2nhiTRI3wvjfNcSXEXw" target="_blank" rel="noopener noreferrer">
                  <img alt="The Tab" src={theTabBrand} className="img-fluid" width="600" height="200" />
                </a>
              </div>
            </div>

            <div className="col-12 col-lg-4 mb-5 mb-lg-0 d-flex justify-content-center">
              <div className="text-center" style={{ maxWidth: '240px' }}>
                <a href="https://www.facebook.com/urn1350/videos/439525566577910/" target="_blank" rel="noopener noreferrer">
                  <img alt="University Radio Nottingham" src={universityRadioNottinghamBrand} className="img-fluid" width="600" height="200" />
                </a>
              </div>
            </div>

            <div className="col-12 col-lg-4 mb-5 mb-lg-0 d-flex justify-content-center">
              <div className="text-center" style={{ maxWidth: '240px' }}>
                <a href="https://impactnottingham.com/2018/10/unisalad-buy-sell-app-launches-in-january-2019/" target="_blank" rel="noopener noreferrer">
                  <img alt="Impact Magazine" src={impactMagazineBrand} className="img-fluid" width="600" height="200" />
                </a>
              </div>
            </div>

            <div className="mt-lg-5 col-12 offset-lg-2 col-lg-4 mb-5 mb-lg-0 d-flex justify-content-center">
              <div className="text-center" style={{ maxWidth: '240px' }}>
                <a href="http://eastmidlandsbusinessnews.co.uk/ex-nottingham-student-launches-unisalad-app/" target="_blank" rel="noopener noreferrer">
                  <img alt="Nottingham Post" src={nottinghamPostBrand} className="img-fluid" width="600" height="200" />
                </a>
              </div>
            </div>

            <div className="mt-lg-5 col-12 col-lg-4 mb-0 mb-lg-0 d-flex justify-content-center">
              <div className="text-center" style={{ maxWidth: '240px' }}>
                <a href="https://cardiffstudentmedia.co.uk/quench/technology/unisalad-nexus-student-life/" target="_blank" rel="noopener noreferrer">
                  <img alt="Quench" src={quenchBrand} className="img-fluid" width="600" height="200" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      ,
      <footer key="footer" className="footer">
        <div className="container">
          <div className="mb-4 d-flex justify-content-between">
            <div className="d-flex flex-column flex-lg-row">
              <div style={{ minWidth: '200px' }}>
                <div className="mb-1">
                  <Link to="/about">About Us</Link>
                </div>
                <div className="mb-1">
                  <Link to="/blog">Blog</Link>
                </div>
                <div className="mb-1">
                  <Link to="/promotions">Promotions</Link>
                </div>
                <div className="mb-1">
                  <Link to="/faq">FAQ</Link>
                </div>
              </div>

              <div style={{ minWidth: '200px' }}>
                <div className="mb-1">
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </div>
                <div className="mb-1">
                  <Link to="/terms-of-service">Terms of Service</Link>
                </div>
                <div className="mb-1">
                  <Link to="/acknowledgements">Acknowledgements</Link>
                </div>
              </div>

              <div style={{ minWidth: '200px' }}>
                <div className="mb-1">
                  <Link to="/apply">Apply</Link>
                </div>
                <div className="mb-1">
                  <Link to="/contact">Contact</Link>
                </div>
              </div>
            </div>

            <div>
              <a href="https://www.facebook.com/unisaladapp/" target="_blank" rel="noopener noreferrer" className="px-2">
                <i className="fab fa-lg fa-fw fa-facebook"></i>
              </a>
              <a href="https://twitter.com/unisalad/" target="_blank" rel="noopener noreferrer" className="px-2">
                <i className="fab fa-lg fa-fw fa-twitter"></i>
              </a>
              <a href="https://www.instagram.com/uni_salad/" target="_blank" rel="noopener noreferrer" className="px-2">
                <i className="fab fa-lg fa-fw fa-instagram"></i>
              </a>
            </div>
          </div>
          <p style={{ fontSize: '0.8rem' }}>© 2024 OFFEE Ltd. All rights reserved</p>
        </div>
      </footer>
    ];
  }
}
