import React from 'react';

import BlogPost from './blog-post';

export default class Blog2 extends React.Component {

  render() {
    return (
      <BlogPost>
        <h1 style={{ marginBottom: '16px' }}>Interview with CRISIS owner: Andrew Smith</h1>

        <h3 style={{ marginBottom: '24px' }}>Coping with a sudden closure, re-opening plans and advice to students struggling</h3>

        <h5 className="mb-4">Universities are shut, students are home, CRISIS is closed. How are you coping?</h5>

        <p>
          Mostly through increased daytime drinking and online pub quizzes. More seriously though, we are doing the
          best with what we can do. As a company, we've furloughed our team (including our zero hours club reps),
          leaving just me to maintain things. It's a cliché, but we really are trying to make the best of a bad
          situation. Some of the team are using the time for training in things like graphic design. I've taken up iOS
          development to help fill the time and add structure to the days. But it goes without saying, we miss the
          social side of the events business and interacting with our amazing customers and team.
        </p>

        <h5 className="mb-4">Do you think the government took the right action in asking public places to close when it did?</h5>

        <p>
          Despite being an expert armchair epidemiologist as we all are now, I would say it's probably too early to
          say. However, I will admit we were f*cked off when the government fudged the closures to begin with by
          sending the mixed message of "don't go out" but then said clubs and pubs could still open.
        </p>

        <p>
          This created a bizarre and potentially dangerous situation where we had a CRISIS still on for 18th March,
          but with future ones cancelled or postponed. That event was only cancelled on the day, when it became clear
          that it was going to be borderline irresponsible to open. We felt the government decision at the time was a
          total cop-out and they needed to go straight in with the closures. Whether this has had a real effect on
          infections, we will have to see when we are clear of COVID-19 and the data scientists can properly strip the
          different variables out.
        </p>

        <h5 className="mb-4">Did you have time to prepare a strategy for the closure of CRISIS?</h5>

        <p>
          In a word - No. We have general plans in place for closing on the night for an emergency or evacuation.
          Things like fire, someone taken seriously ill, road closures, road traffic accidents outside, things like
          that. But the unwritten assumption is that you will be back in action pretty rapidly. Those types of
          scenarios are also insured. COVID-19 was not, so it's fair to say we've taken a hit.
        </p>

        <h5 className="mb-4">Have you had to deal with refunding of tickets for students? If so, how has the process been?</h5>

        <p>
          Refunding tickets has been a painful experience. Fortunately, we do ringfence ticket money, so we don't have
          access to it till after an event occurs. This way we can't spend money we don't have. That said, we've been
          trying to postpone rather than cancel events where possible and give people tickets to new events instead.
          Problem being, we don't know when the next event will be so we've been stalling for time.
        </p>

        <p>
          CRISIS events that were scheduled for the 18th and 25th March have been refunded. The 1st April All-Nighter
          is pending as we are hoping for a June All-Nighter replacement if restrictions are lifted. If and when we
          get new information from the government that says this definitely won't happen then we'll go ahead and
          process those refunds too.
        </p>

        <p>
          Booking fees are, however, being lost. In normal circumstances they are refunded as courtesy, but frankly
          the ticketing providers cannot afford to do so on this scale. Credit card companies and payment providers
          charge both for processing a transaction and refunding it. A few refunds here and there we can afford it.
          For refunding all ticket sales, however, it just isn't financially viable.
        </p>

        <h5 className="mb-4">Are you doing anything social with CRISIS during this downtime?</h5>

        <p>
          We are loving TikTok right now so spending many an hour swiping through and creating new videos. We recently
          did a competition to win a black card and had so many great entries we gave out 3 winners instead. You can
          check us out @wearecrisis.
        </p>

        <p>
          We wanted to do a big live stream of CRISIS, however, it's difficult/impossible to get the right copyrights
          on the music. The license that allows for the playing of music in the club doesn't extend to Facebook or
          Instagram. If we go live on those platforms, we can have our accounts shutdown by the big music studios.
          Personal accounts can get away with it, but not businesses. This is a shame because we would have loved to
          have done a Wednesday Quarantine special.
        </p>

        <h5 className="mb-4">
          When you do you think students will be back at university? Have you planned for a best-case and worst-case
          scenario?
        </h5>

        <p>
          My heart says June, my head says September. Following what's in the media, I think it's reasonable to assume
          that schools will probably open within a month. Whether they will include Universities in this I am not
          sure. Since University students don't prevent their parents from going to work, I would lean to them not
          opening. Moreover, even if Nottingham University does reopen, I find it unlikely the social scene will have
          been allowed to reboot. Looking around other countries, nightclubs and mass participation events like ours
          will be the last on the list to come back.
        </p>

        <p>
          That said, I am hopeful, and the second we get a green light we will re-open and I don't care if it falls on
          a Sunday night, CRISIS will be back immediately.
        </p>

        <h5 className="mb-4">When do I expect the first CRISIS night to be?</h5>

        <p>
          I would hope June so we can do a big finish for everyone graduating this year - "The Class of Covid-19" as
          I'm sure they will forever be known. This would also enable Cock-Soc to go ahead and generally allow a week
          of partying which is part of finishing University and saying goodbye to people.
        </p>

        <p>
          If this doesn't happen, we will do our best to do at least one big CRISIS in the summer, possibly in
          Nottingham, possibly in London if people no longer have their student accommodation available to them. We
          will put it out there to our social following as we near the time to decide where and when.
        </p>

        <p>
          We are already in conversations with the Union about what events might be possible at what stage in the
          Calendar.
        </p>

        <h5 className="mb-4">Are you planning on anything special for the first night back?</h5>

        <p>
          We might finally update the playlist if you're lucky! More seriously though, it depends on how much notice
          we get. If we know a couple of weeks in advance, then we would ramp up production to insane levels. That
          said, on the very first one, it's going to be all about the people - An electric atmosphere of everyone
          reconnecting for the first time in months. It will be incredible. If anything, we should keep it as simple
          as possible and leave you guys to it.
        </p>

        <h5 className="mb-4">Do you have plans to make Crisis even bigger next year?</h5>

        <p>
          We are lucky enough to sell out every week but I'm sure we can make it even better. Our main goals for next
          year are:

          <ul>
            <li>New service app we have already been developing that allows you to order drinks on your phone and collect at a bar service point</li>
            <li>Reducing or preferably eliminating ticket touting</li>
            <li>Introducing a priority membership to replace our VIP tickets. People who sign up for this will be able to get hold of tickets right up till a couple of days before.</li>
            <li>Enhanced production on every event to ensure we are always the best student night in the UK</li>
            <li>Relaunch of our bi-annual CRISIS reunion club nights for graduates.</li>
          </ul>
        </p>

        <h5 className="mb-4">Any words of advice for students to survive this unsettling period?</h5>

        <p>
          I feel for anyone who has suffered the loss of a loved one in this pandemic. I'd encourage anyone who has
          been affected, whether this is through grief, or the change to our daily lives to reach out to some of the
          wonderful mental health care providers that this country has to offer. It's ok to not be ok.
        </p>

        <p>
          Those of us in a less dire state, perhaps just suffering from being boxed up at home with the parents, it
          won't last forever. And as our new national hero Captain Tom said "the sun will shine on you again and the
          clouds will go away".
        </p>
      </BlogPost>
    );
  }
}
