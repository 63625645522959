import React from 'react';
import { Link } from 'react-router-dom';

import Page from '../page';

export default class BlogPost extends React.Component {

  render() {
    return (
      <Page>
        <div style={{ marginBottom: '16px' }}><Link to="/blog" style={{ color: 'rgb(172, 148, 148)' }}>Back to all blog posts</Link></div>

        {this.props.children}
      </Page>
    );
  }
}
